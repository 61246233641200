import React, { Component } from "react";
import OxfordBanner from "../../assets/banner-oxford.jpg";
import oxford from "../../assets/oxford.png";
import AnimatePopup from "../animatePopup";
import ContactForm from "./ContactForm";
import Ctafifth from "./cta-five";
import Client1image from "../../assets/case-study-details-img-1.jpg";
import NextBlogImg from "../../assets/blog_img_05.jpg";
import FrutazHeroImg from "../../assets/frutaz-hero-img.png";
import frutazroas01 from "../../assets/frutaz-roas01.png";
import frutazroas02 from "../../assets/frutaz-roas02.png";
import aimanLaptop from "../../assets/frutaz-aiman-laptop.png";
import frutazLaptop from "../../assets/frutaz-laptop.png";
import frutazLaptop2 from "../../assets/frutaz-laptop02.png";
import frutazMobile1 from "../../assets/frutaz-mobile01.png";
import frutazMobile2 from "../../assets/frutaz-mobile02.png";
import frutazMobile3 from "../../assets/frutaz-mobile03.png";
import frutazBlue from "../../assets/frutaz-blue-image.png";
import frutazYellow from "../../assets/frutaz-yellow-image.png";
import MoreCaseTemplate from "./MoreCaseStudyTemplate";
import { scrollText } from '../../your-script';

export default class FrutazCaseStudy extends Component {
  
  componentDidMount() {
    window.scrollTo(0, 0);
    scrollText();
  }
  render() {
    return (
      <div className="">
        <div className="row background-2">
          <div className="container text-center px-0">
            <h1 id="heading-main">Frutaz Case study</h1>
          </div>
        </div>
        <div className="container-md px-0">
          <div className="p-md-5 p-2 mx-0 position-relative">
            {/* <img src={NextBlogImg} /> */}
            <img
              src={FrutazHeroImg}
              className="max-h-800px img-fluid"
              data-aos="zoom-in"
              data-aos-duration="900"
              alt=""
            />
            {/* <div className="inline-flex items-start absolute left-2/4 -translate-x-2/4 top-12">
            <span data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="100">
                <svg width="43" height="43" className="inline" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M34.9278 8.01697C31.441 4.56504 26.6247 2.43016 21.3038 2.43016C15.9834 2.43016 11.1665 4.56504 7.6802 8.01697C4.19342 11.4689 2.03675 16.2374 2.03675 21.5046C2.03675 26.7723 4.19342 31.5408 7.6802 34.9928C11.167 38.4447 15.9834 40.5796 21.3038 40.5796C26.6242 40.5796 31.441 38.4447 34.9278 34.9928C38.4141 31.5408 40.5708 26.7723 40.5708 21.5046C40.5708 16.2374 38.4141 11.4689 34.9278 8.01697ZM12.028 19.7886C11.4443 16.3425 10.8611 12.8964 10.278 9.44975C11.1724 9.44975 12.3397 9.44975 13.2342 9.44975C14.0047 11.2631 14.8396 13.0145 16.5788 14.6477C18.1169 13.3467 18.7576 11.4704 19.2626 9.50737C20.5947 9.50737 21.9273 9.50737 23.2593 9.50737C23.7643 11.4704 24.405 13.3467 25.9431 14.6477C27.6828 13.014 28.5173 11.2631 29.2878 9.44975C30.1827 9.44975 31.3496 9.44975 32.244 9.44975C31.6604 12.8959 31.0772 16.342 30.494 19.7886C29.3272 19.7886 28.1603 19.7886 26.9935 19.7886C24.0869 17.0083 18.4474 16.9966 15.529 19.7886C14.3617 19.7886 13.1948 19.7886 12.028 19.7886ZM12.0432 21.247H30.4783V25.2267H12.0432V21.247ZM12.0432 26.1516H30.4783V30.1313H12.0432V26.1516ZM12.0432 31.0561H30.4783V35.0359H12.0432V31.0561ZM21.3038 0.61438C27.1301 0.61438 32.4058 2.95263 36.224 6.73285C40.0427 10.5131 42.4044 15.7358 42.4044 21.5041C42.4044 27.2725 40.0422 32.4952 36.224 36.2759C32.4058 40.0566 27.1306 42.3949 21.3038 42.3949C15.4769 42.3949 10.2017 40.0566 6.38354 36.2764C2.56485 32.4957 0.203125 27.2735 0.203125 21.5046C0.203125 15.7362 2.56485 10.5136 6.38354 6.73333C10.2022 2.95311 15.4774 0.61438 21.3038 0.61438Z" fill="white"/>
                </svg>
            </span>
            <span className="text-[43px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="200">W</span>
            <span className="text-[43px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="300">E</span>
            <span className="text-[43px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="400">S</span>
            <span className="text-[43px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="500">T</span>
            <span className="text-[43px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="600">P</span>
            <span className="text-[43px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="700">O</span>
            <span className="text-[43px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="800">I</span>
            <span className="text-[43px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="900">N</span>
            <span className="text-[43px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="1000">T</span>
            <span
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="1100">
                <svg width="9" height="9" className="inline" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.18348 4.37231C5.41016 4.26723 5.59603 4.12052 5.73765 3.93507C5.91024 3.71039 5.99875 3.42761 5.99875 3.09593C5.99875 2.85818 5.94958 2.65336 5.8532 2.48776C5.75732 2.32459 5.62603 2.18852 5.46278 2.08587C5.3015 1.98177 5.12791 1.91398 4.94794 1.88638C4.77682 1.85926 4.56442 1.8457 4.3161 1.8457H3.10352V6.2675H3.89567V4.59795H4.37608L5.72783 6.2675H6.7565L5.18348 4.37231ZM5.18004 3.13709C5.18004 3.2717 5.16234 3.38791 5.12693 3.48427C5.09448 3.57724 5.04432 3.65519 4.97253 3.72395C4.89582 3.79755 4.80486 3.84549 4.6957 3.8697C4.57129 3.89585 4.43214 3.9094 4.28167 3.9094H3.89567V2.54587H4.34412C4.49557 2.54587 4.62244 2.55652 4.7198 2.57541C4.81224 2.59429 4.89189 2.62577 4.96122 2.67177C5.03547 2.7197 5.0881 2.78023 5.12449 2.8577C5.16186 2.9376 5.18004 3.03153 5.18004 3.13709Z" fill="white"/>
                <path d="M4.7483 8.01159C2.53359 8.01159 0.731445 6.23696 0.731445 4.05512C0.731445 1.87424 2.53359 0.0996094 4.7483 0.0996094C6.963 0.0996094 8.76517 1.87424 8.76517 4.05512C8.76517 6.23696 6.963 8.01159 4.7483 8.01159ZM4.7483 0.766366C2.90632 0.766366 1.40806 2.24175 1.40806 4.0556C1.40806 5.86945 2.90632 7.3458 4.7483 7.3458C6.59027 7.3458 8.08855 5.86945 8.08855 4.0556C8.08855 2.24175 6.59027 0.766366 4.7483 0.766366Z" fill="white"/>
                </svg>
            </span>
        </div>
        <div className="inline-flex items-start absolute left-2/4 -translate-x-2/4 top-32">
            <span className="text-[70px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="1300">W</span>
            <span className="text-[70px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="1400">E</span>
            <span className="text-[70px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="1500">S</span>
            <span className="text-[70px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="1600">T</span>
            <span className="text-[70px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="1700">P</span>
            <span className="text-[70px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="1800">O</span>
            <span className="text-[70px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="1900">I</span>
            <span className="text-[70px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="2000">N</span>
            <span className="text-[70px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="2100">T</span>
            &nbsp;
            <span className="text-[70px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="2200">P</span>
            <span className="text-[70px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="2300">A</span>
            <span className="text-[70px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="2400">K</span>
            <span className="text-[70px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="2500">I</span>
            <span className="text-[70px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="2600">S</span>
            <span className="text-[70px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="2700">T</span>
            <span className="text-[70px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="2800">A</span>
            <span className="text-[70px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="2900">N</span>
        </div>
        <div className="inline-flex items-start absolute left-2/4 -translate-x-2/4 top-52">
            <p className="font-medium text-[24px] text-white"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="3000">Best Kitchen Appliances Provider in Pakistan</p>
        </div> */}
          </div>
        </div>
        <div className="bg-F8B123 my-md-5 py-5 wavy-bg">
          <div className="p-md-5 p-2 d-flex gap-20 flex-md-row flex-column">
            <div className="d-inline-flex align-items-center justify-content-md-start justify-content-center gap-8">
              <img src={frutazroas01} className="img-fluid" alt="" />
              <div>
                <p className="leading-none text-white font-weight-bolder h1 mb-0">
                  PKR 7.6CR
                </p>
                <p className="text-white h4 mb-0 leading-none">Annual Sales</p>
              </div>
            </div>
            <div className="d-inline-flex align-items-center justify-content-md-start justify-content-center gap-8">
              <img src={frutazroas02} className="img-fluid" alt="" />
              <div>
                <p className="leading-none text-white font-weight-bolder h1 mb-0">
                  800%
                </p>
                <p className="text-white h4 leading-none mb-0">
                  Return on Investment
                </p>
              </div>
            </div>
          </div>
          <div className="border-md-top border-md-bottom border-md-dark mb-md-5">
            <div className="pd">
              <div className="scroll-header">
                <p
                  className="scroll-text text-200px text-FFDE9B font-weight-bolder"
                  data-inviewport
                >
                  3x greater
                </p>
              </div>

              <div className="scroll-header">
                <p
                  className="scroll-text text-200px text-FFDE9B font-weight-bolder"
                  data-inviewport
                >
                  engagement rate
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="py-5">
          <div className="max-w-600px mx-auto text-center pb-5">
            <h6
              className="text-151515 font-weight-bolder display-4"
              data-aos="zoom-in"
              data-aos-duration="900"
            >
              The Client
            </h6>
            <p className="text-A1A1A1 h6">
              Aiman Food and Beverages Co. is a renowned and highly regarded
              business that has focused on producing and selling Fruit juices
              and beverages. By providing its cherished customers with high
              quality juice products and excellent customer care services
              throughout time, the business has gained their happiness and
              trust. Additionally, they have a competitive edge to work
              successfully and effectively in a highly-competitive niche.
            </p>
          </div>
          <div className="bg-F8B123">
            <div className="px-5 pt-5 text-center">
              <img src={aimanLaptop} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
        <div className="px-5 d-flex align-items-center justify-content-center flex-column">
          <div className="max-w-850px py-md-5">
            <h6 className="text-151515 font-weight-bolder h1 leading-none pb-3 display-2 text-center text-lg-left">
              Challenges Identified by ExpoBird
            </h6>
            <p className="text-A1A1A1 h5 py-3 text-center text-lg-left">
              Monitoring customer perception and brand recognition on social
              media is important for any company seeking to promote itself on
              digital channels. Aiman Food and Beverages Co. encountered
              difficulty implementing strategies on social media. They
              approached ExpoBird with the intent of building a platform that
              streamlines social monitoring and handles all ORM activities.
            </p>
            <ul className="pl-lg-3 py-lg-3 py-1 text-center text-lg-left">
              <li className="text-A1A1A1 h5 list-disc">
                Lack of brand awareness
              </li>
              <li className="text-A1A1A1 h5 list-disc">
                Less interactive Social Media presence
              </li>
            </ul>
          </div>
          <div className="d-flex gap-16 pb-5 flex-lg-row flex-column">
            <div
              className="bg-FFB800 rounded-30 h-fit"
              data-aos="fade-right"
              data-aos-duration="900"
            >
              <div className="p-lg-5 p-2">
                <h6 className="text-white font-weight-bolder display-4 text-center">
                  Less interactive
                </h6>
                <p className="text-white h5 text-center">Social Media</p>
              </div>
              <div className="px-lg-5 pb-3">
                <img src={frutazLaptop} className="mx-auto img-fluid" alt="" />
              </div>
            </div>
            <div
              className="bg-FEA31A rounded-30 mt-48"
              data-aos="fade-left"
              data-aos-duration="900"
            >
              <div className="p-lg-5 p-2">
                <h6 className="text-white font-weight-bolder display-4 text-center">
                  Less interactive
                </h6>
                <p className="text-white h5 text-center">Social Media</p>
              </div>
              <div className="px-lg-5 pb-3">
                <img src={frutazLaptop} className="mx-auto img-fluid" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex py-lg-5 flex-column flex-lg-row">
          <div className="col d-flex justify-content-center align-items-center p-lg-5 p-2 bg-F8B123 rounded-r-30">
            <img src={frutazLaptop2} className="img-fluid" alt="" />
          </div>
          <div className="p-lg-5 p-2 col">
            <h6 className="text-151515 font-weight-bolder display-4 text-center text-lg-left">
              The Approach
            </h6>
            <p className="py-3 text-ACACAC h5 text-center text-lg-left">
              ExpoBird did an audit of Aiman Food and Beverages Co. social media
              channels before making a marketing strategy, so that we could set
              baselines for audience growth, brand visibility, and engagement.
              The audit also helped us figure out what they were currently doing
              well and where they could improve or what were the opportunities.
              We defined the voice of the brand, positioning of the brand and
              made plans for how to increase the engagement of customers.We did
              a thorough analysis about our competitive brands to see where we
              are lacking behind.
            </p>
          </div>
        </div>
        <div className="px-md-5 p-2 d-flex align-items-center justify-content-center">
          <div className="max-w-850px py-md-5">
            <h6 className="text-151515 font-weight-bolder display-3 leading-none pb-3 text-center text-lg-left">
              Our Campaign
            </h6>
            <ul className="pl-lg-3 py-lg-3 py-1 text-center text-lg-left">
              <li className="list-disc text-A1A1A1 h5 text-center text-lg-left">
                Generate and publish product-relevant content on Social Media
                platforms, interact with users, and use competitive analysis
                research to boost online prevalence.
              </li>
              <li className="list-disc text-A1A1A1 h5">
                Meta Ads to further increase awareness of the company.
              </li>
            </ul>
          </div>
        </div>
        <div className="bg-F8B123">
          <div className="px-md-5 py-5 d-flex justify-content-center align-items-center gap-20 flex-wrap">
            <img src={frutazMobile1} className="img-fluid col-mx-4" alt="" />
            <img src={frutazMobile2} className="img-fluid col-mx-4" alt="" />
            <img src={frutazMobile3} className="img-fluid col-mx-4" alt="" />
          </div>
        </div>
        <div className="px-md-5 px-2 py-5">
          <div className="d-flex flex-column flex-lg-row">
            <div className="text-center col px-md-2 px-0">
              <h6 className="text-151515 font-weight-bolder display-2">
                The Result
              </h6>
            </div>
            <div className="col px-md-2 px-0">
              <p className="text-A1A1A1 h5 pb-5 text-center text-lg-left">
                With our targeted digital marketing efforts, we established
                Oxford as a dominant workout apparel and unbreakable clothing
                brand with a strong market position. Not only that but enlisting
                ExpoBird as a marketing partner helped them increase product
                sales, placing their company on the path to future scalability.
                For the first time, a proper and engaging theme was followed in
                the whole winter campaign that year in 2019. Several designs
                were created with a uniform theme that helped the audience to
                recall the brand identity of Oxford. Be it a billboard or
                Panaflex or social media posts or magazines articles or online
                newspaper, a uniform theme was followed all the way through from
                start to end.
              </p>
              <div className="d-flex gap-20 pb-5">
                <div className="col">
                  <p className="leading-none text-151515 font-weight-bolder display-3 text-center text-lg-left">
                    350%
                  </p>
                  <p className="text-A1A1A1 h4 text-center text-lg-left">2 million PKR of revenue</p>
                </div>

                <div className="col">
                  <p className="leading-none text-151515 font-weight-bolder display-3 text-center text-lg-left">
                    800%
                  </p>
                  <p className="text-A1A1A1 h4 text-center text-lg-left">8X Return on Ad-Spend</p>
                </div>
              </div>
              <div className="d-flex gap-20 pb-5">
                <div className="col">
                  <p className="leading-none text-151515 font-weight-bolder display-3 text-center text-lg-left">
                    290%
                  </p>
                  <p className="text-A1A1A1 h4 text-center text-lg-left">1098 Purchases of products</p>
                </div>
                <div className="col">
                  <p className="leading-none text-151515 font-weight-bolder display-3 text-center text-lg-left">
                    370%
                  </p>
                  <p className="text-A1A1A1 h4 text-center text-lg-left">22K Average daily sale</p>
                </div>
              </div>
            </div>
          </div>
        </div>
          <div className="p-lg-5 p-2">
            <div className="d-flex align-items-center justify-content-around flex-column flex-lg-row">
              <div className="bg-95BFFC rounded-30 max-w-600px max-h-500px">
                <img src={frutazBlue} className="p-md-5 p-3 img-fluid" alt="" />
              </div>
              <div className="bg-FFB800 rounded-30 max-w-600px max-h-500px mt-3 mt-lg-0">
                <img src={frutazYellow} className="p-md-5 p-3 img-fluid" alt="" />
              </div>
            </div>
        </div>

























        {/* <div className="container">
          <div className="row pt-4 pt-sm-5">
            <div className="col-12 col-lg-6 px-1 px-md-3 order-12 order-sm-12 order-md-12 order-lg-1 order-xl-1">
              <div className="p-3">
                <h2 className="BlogPage-Header mt-5">
                  Winter Campaign 2019 – 20
                </h2>
                <p className="BlogPage-Text pt-4 pb-2">
                  How ExpoBird helped Oxford to reach the target audience of
                  more than 2 Million People in a month and break the old time
                  record of last year’s sales?
                </p> */}
                {/* <ul className="text-center text-md-left">
                                    <li>
                                        <p className="BlogPage-Text">
                                            <span className="mr-4">Icon</span>
                                            One design for your desktop, tab and mobile.
                                        </p>
                                    </li>
                                    <li>
                                        <p className="BlogPage-Text">
                                            <span className="mr-4">Icon</span>
                                            One design for your desktop, tab and mobile.
                                        </p>
                                    </li>
                                    <li>
                                        <p className="BlogPage-Text">
                                            <span className="mr-4">Icon</span>
                                            One design for your desktop, tab and mobile.
                                        </p>
                                    </li>
                                </ul> */}
              {/* </div>
            </div>
            <div className="col-12 col-lg-6 px-1 px-md-5 mt-5 order-1 order-sm-1 order-md-1 order-lg-12 order-xl-12">
              <div className="NextBlog-Img mb-3 mt-5">
                <img src={NextBlogImg} />
              </div>
            </div>
          </div>
          <div className="row pt-4 pt-sm-5">
            <div className="col-12 col-lg-6 px-1 px-md-5 mt-5">
              <div className="NextBlog-Img mb-3 mt-5">
                <img src={NextBlogImg} />
              </div>
            </div>
            <div className="col-12 col-lg-6 px-1 px-md-3">
              <div className="p-3">
                <h2 className="BlogPage-Header mt-5">Summary</h2>
                <p className="BlogPage-Text pt-4 pb-2">
                  Oxford is the clothing brand that is famous for the winter
                  products across Pakistan. It is the only pioneer in the nation
                  that produces high quality sweaters with 100% guarantee. In
                  addition, they sell jackets, mufflers, sweaters, socks and
                  belts.
                </p>
                <p className="BlogPage-Text pb-4">
                  Oxford’s primary objective of this campaign was to exceed last
                  year’s winter sales with high margins and to create awareness
                  among the masses for the range of its products. Because this
                  was the first time that Oxford was going huge on more than one
                  digital platforms.
                </p> */}
                {/* <ul className="text-center text-md-left">
                                    <li>
                                        <p className="BlogPage-Text">
                                            <span className="mr-4">Icon</span>
                                            One design for your desktop, tab and mobile.
                                        </p>
                                    </li>
                                    <li>
                                        <p className="BlogPage-Text">
                                            <span className="mr-4">Icon</span>
                                            One design for your desktop, tab and mobile.
                                        </p>
                                    </li>
                                    <li>
                                        <p className="BlogPage-Text">
                                            <span className="mr-4">Icon</span>
                                            One design for your desktop, tab and mobile.
                                        </p>
                                    </li>
                                </ul> */}
              {/* </div>
            </div>
          </div>
          <div className="row pt-4 pt-sm-5">
            <div className="col-12 col-lg-6 px-1 px-md-3 order-12 order-sm-12 order-md-12 order-lg-1 order-xl-1">
              <div className="p-3">
                <h2 className="BlogPage-Header mt-5">Insights</h2>
                <p className="BlogPage-Text pt-4 pb-2">
                  As November passed by, it was an ideal time to strike because
                  winter conditions prevailed all over Pakistan including
                  Karachi. So ExpoBird’s plan was to execute the plan in mind.
                  And the plan was to go rule on digital marketing platforms
                  Facebook, Instagram, YouTube and also online newspapers that
                  have the vote of most trusted newspaper based on Oxford’s
                  target audience.
                </p> */}
                {/* <p className="BlogPage-Text pb-4">
                                Creating an elegant website with super UI/UX is something that can be done by a lot of companies, but making this website run without internet is something that only few companies could do. This is something that has never been done before in Pakistan by any builder and developer.
                                </p> */}
                {/* <ul className="text-center text-md-left">
                                    <li>
                                        <p className="BlogPage-Text">
                                            <span className="mr-4">Icon</span>
                                            One design for your desktop, tab and mobile.
                                        </p>
                                    </li>
                                    <li>
                                        <p className="BlogPage-Text">
                                            <span className="mr-4">Icon</span>
                                            One design for your desktop, tab and mobile.
                                        </p>
                                    </li>
                                    <li>
                                        <p className="BlogPage-Text">
                                            <span className="mr-4">Icon</span>
                                            One design for your desktop, tab and mobile.
                                        </p>
                                    </li>
                                </ul> */}
              {/* </div>
            </div>
            <div className="col-12 col-lg-6 px-1 px-md-5 mt-5 order-1 order-sm-1 order-md-1 order-lg-12 order-xl-12">
              <div className="NextBlog-Img mb-3 mt-5">
                <img src={NextBlogImg} />
              </div>
            </div>
          </div>
          <div className="row pt-4 pt-sm-5">
            <div className="col-12 col-lg-6 px-1 px-md-5 mt-5">
              <div className="NextBlog-Img mb-3 mt-5">
                <img src={NextBlogImg} />
              </div>
            </div>
            <div className="col-12 col-lg-6 px-1 px-md-3">
              <div className="p-3">
                <h2 className="BlogPage-Header mt-5">Solution</h2>
                <p className="BlogPage-Text pt-4 pb-2">
                  Highly eye-catching posts were created for Facebook and
                  Instagram for Oxford’s social media presence. These posts were
                  comprised of high profile animated videos that captured the
                  mind of audience and made them stay on their social media
                  pages for long and review.
                </p>
                <p className="BlogPage-Text pb-4">
                  Secondly, ExpoBird took a top gear by engaging The Idiotz
                  (high profile Vloggers on YouTube) for Oxford’s winter
                  campaign 19-20. As a result, the expected yield of this
                  campaign was doubled in no time.
                </p>
                <p className="BlogPage-Text pb-4">
                  Additionally, it was a unanimous decision of the Oxford’s and
                  ExpoBird’s key management that the ad should be published on
                  Tribune’s online newspaper for a period of one month. This
                  would make hundreds and thousands of people to recall the
                  brand existence.
                </p>
                <p className="BlogPage-Text pb-4">
                  For the first time, a proper and an engaging theme was
                  followed in the whole winter campaign that year in 2019.
                  Several designs were created with the uniform theme that
                  helped the audience to recall the brand identity of Oxford. Be
                  it a billboard or Panaflex or social media posts or magazines
                  articles or online newspaper, a uniform theme was followed all
                  the way through from start to an end.
                </p> */}
                {/* <ul className="text-center text-md-left">
                                    <li>
                                        <p className="BlogPage-Text">
                                            <span className="mr-4">Icon</span>
                                            One design for your desktop, tab and mobile.
                                        </p>
                                    </li>
                                    <li>
                                        <p className="BlogPage-Text">
                                            <span className="mr-4">Icon</span>
                                            One design for your desktop, tab and mobile.
                                        </p>
                                    </li>
                                    <li>
                                        <p className="BlogPage-Text">
                                            <span className="mr-4">Icon</span>
                                            One design for your desktop, tab and mobile.
                                        </p>
                                    </li>
                                </ul> */}
              {/* </div>
            </div>
          </div>
          <div className="row pt-sm-5 pt-4">
            <div className="col-12 my-5 text-center">
              <h2 className="BlogPage-Header">Scroreboard</h2>
            </div>
            <div className="col-12 col-md-6 text-center">
              <div className="Seperator">
                <ul>
                  <li>
                    <p className="BlogPage-Text">
                      <span className="mr-4">Icon</span>
                      One design for your desktop, tab and mobile.
                    </p>
                  </li>
                  <li>
                    <p className="BlogPage-Text">
                      <span className="mr-4">Icon</span>
                      One design for your desktop, tab and mobile.
                    </p>
                  </li>
                  <li>
                    <p className="BlogPage-Text">
                      <span className="mr-4">Icon</span>
                      One design for your desktop, tab and mobile.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-md-6 text-center">
              <div className="Seperator2">
                <ul>
                  <li>
                    <p className="BlogPage-Text">
                      <span className="mr-4">Icon</span>
                      One design for your desktop, tab and mobile.
                    </p>
                  </li>
                  <li>
                    <p className="BlogPage-Text">
                      <span className="mr-4">Icon</span>
                      One design for your desktop, tab and mobile.
                    </p>
                  </li>
                  <li>
                    <p className="BlogPage-Text">
                      <span className="mr-4">Icon</span>
                      One design for your desktop, tab and mobile.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 my-5 text-center">
              <h2 className="BlogPage-Header">Explore more Case Studies</h2>
            </div>
            <MoreCaseTemplate
              title1={"Diamond Homes"}
              description1={"Desc"}
              image1={NextBlogImg}
              Link1={"/diamond-case-study"}
              title2={"Insignia"}
              description2={"Desc"}
              image2={NextBlogImg}
              Link2={"/Insignia"}
            /> */}
            {/* <div className="col-12 col-md-6 px-1 px-md-5 mb-5">
                            <div className=" BlogContent-bg">
                                <div className="NextBlog-img mb-3">
                                    <img src={NextBlogImg}/>
                                </div>
                                <div className="p-4">
                                    <h2 className="BlogPage-Title">Case Study Title</h2>
                                    <p className="BlogPage-Text">
                                    Aenean consectetur massa quis sem volutpat, a condimentum tortor pretium. Cras id ligula consequat, sagittis nulla at,
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 px-1 px-md-5 mb-5">
                            <div className=" BlogContent-bg">
                                <div className="NextBlog-img mb-3">
                                    <img src={NextBlogImg}/>
                                </div>
                                <div className="p-4">
                                    <h2 className="BlogPage-Title">Case Study Title</h2>
                                    <p className="BlogPage-Text">
                                    Aenean consectetur massa quis sem volutpat, a condimentum tortor pretium. Cras id ligula consequat, sagittis nulla at,
                                    </p>
                                </div>
                            </div>
                        </div> */}
          {/* </div>
        </div> */}

        {/* <div className="row contact_container">
                    <div className="col-md-2 mt-5"></div>
                    <div className="col-md-8 text-center my-5 py-5 px-5">
                        <img className="" src={oxford} height="100"/>
                        <h1 className="text-capitalize contact-header2">Digital Transformation</h1>
                        <p className="header-description">Our SEO implemented strategy has maximise revenue for Oxford. We focused on the PR of our every Brand to increase their ROI.
                        </p>
                        <p className="header-description">
                        <strong className="mt-1">
                        That’s when Oxofrd Store asked us to help them expand even more in the coming year. Challenge accepted.</strong></p>
                        
                    </div>
                    <div className="col-md-2 mt-5"></div>
                </div>
                <div className="container case-study-description">
                        <div className="col-12">
                            <h2>
                                    Winter Campaign 2019 – 20
                            </h2>
                            <p>
                            How ExpoBird helped Oxford to reach the target audience of more than 2 Million People in a month and break the old time record of last year’s sales?
                            </p>
                            <h2 >
                            Summary
                            </h2>
                            <p>
                            Oxford is the clothing brand that is famous for the winter products across Pakistan. It is the only pioneer in the nation that produces high quality sweaters with 100% guarantee. In addition, they sell jackets, mufflers, sweaters, socks and belts.<br/>
                            Oxford’s primary objective of this campaign was to exceed last year’s winter sales with high margins and to create awareness among the masses for the range of its products. Because this was the first time that Oxford was going huge on more than one digital platforms.
                            </p>
                            <h2 >
                            Insights
                            </h2>
                            <p>
                            As November passed by, it was an ideal time to strike because winter conditions prevailed all over Pakistan including Karachi. So ExpoBird’s plan was to execute the plan in mind. And the plan was to go rule on digital marketing platforms Facebook, Instagram, YouTube and also online newspapers that have the vote of most trusted newspaper based on Oxford’s target audience.
                            </p>
                            <h2 >
                            Solution
                            </h2>
                            <p>
                            Highly eye-catching posts were created for Facebook and Instagram for Oxford’s social media presence. These posts were comprised of high profile animated videos that captured the mind of audience and made them stay on their social media pages for long and review. <br/>
                            Secondly, ExpoBird took a top gear by engaging The Idiotz (high profile Vloggers on YouTube) for Oxford’s winter campaign 19-20. As a result, the expected yield of this campaign was doubled in no time.<br/>
                            Additionally, it was a unanimous decision of the Oxford’s and ExpoBird’s key management that the ad should be published on Tribune’s online newspaper for a period of one month. This would make hundreds and thousands of people to recall the brand existence.<br/>
                            For the first time, a proper and an engaging theme was followed in the whole winter campaign that year in 2019. Several designs were created with the uniform theme that helped the audience to recall the brand identity of Oxford. Be it a billboard or Panaflex or social media posts or magazines articles or online newspaper, a uniform theme was followed all the way through from start to an end.
                            </p>

                        </div>
                </div>
                <div className="row marginTop3">
                    <div className="col-12 case-study-image ">
                        <img src={OxfordBanner} width="100%"/>
                    </div>                    
                </div>
                <div className="row text-center">
                <div className="container case-study-description">
                    <div className="row">
                        <div className="col-12">
                            <h2>
                                    Result
                            </h2>
                            <p>
                            How ExpoBird helped Oxford to reach the target audience of more than 2 Million People in a month and break the old time record of last year’s sales?
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4 text-center">
                        <h2 className="case-study-result">
                                   44%
                            </h2>  
                            <p>
                            INCREASE IN<br/>TRAFFIC YEAR ON YEAR
                            </p>
                        </div>
                        <div className="col-4 text-center">
                        <h2 className="case-study-result">
                                  80%
                            </h2 >  
                            <p>
                            INCREASE IN<br/>ONLINE SALES
                            </p>
                        </div>
                        <div className="col-4 text-center">
                        <h2 className="case-study-result">
                                   70%
                            </h2>  
                            <p>
                            INCREASE IN<br/>ONLINE TRANSACTIONS
                            </p>
                        </div>
                        </div>

                </div>
                </div>
                <Ctafifth/>
                <ContactForm/> */}
      </div>
    );
  }
}
