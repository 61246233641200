import React, { Component } from 'react';
import { BrowserRouter as Router, Link} from "react-router-dom";
import ReactDOM from 'react-dom'
import AG from '../../assets/ag.png'
import moj from '../../assets/mall of jinnah.png'
import premierbuilder from '../../assets/premier builders.png'
import afabco from '../../assets/afabco.png'
import pricetech from '../../assets/price tech.png'
import profitt from '../../assets/profitt.png'
import quick from '../../assets/quick account.png'
import rainbow from '../../assets/rainbow linen.png'
import mgc from '../../assets/mgc.png'
import spot from '../../assets/spot bazaar.png'
import world from '../../assets/the pleasing world.png'
import narkins from '../../assets/narkins.png'
import memongroup from '../../assets/Memon Group.png'
import jackjees from '../../assets/jackjees.png'
import lsn from '../../assets/lsn.png'
import wood from '../../assets/woodn wood.png'
import westpoint from '../../assets/west point.png'
import walnut from '../../assets/walnut aparel.png'
import jewellery from '../../assets/jewellery palace.png'
import ameeri from '../../assets/ameeri.png'
import happy from '../../assets/happy.png'
import harbour from '../../assets/Harbour.png'
import herbal from '../../assets/herba light.png'
import hill from '../../assets/hill crest.png'
import homes from '../../assets/homes perception.png'
import hut from '../../assets/hut provider.png'
import agp from '../../assets/agp.png'
import aliLakhani from '../../assets/ali-lakhani.png'
import Allied from '../../assets/Allied.png'
import dhanani from '../../assets/dhanani builder.png'
import apimatic from '../../assets/apimatic.png'
import doha from '../../assets/doha.png'
import gaba from '../../assets/gaba national.png'
import gm from '../../assets/gm builders.png'
import frune from '../../assets/frune foods.png'
import aryResidency from '../../assets/ary-residency.png'
import atlasInsurance from '../../assets/atlas-insurance.png'
import aurDekhao from '../../assets/aur-dekhao.png'
import bahriaTown from '../../assets/bahria-town.png'
import berry from '../../assets/berry store.png'
import crystal from '../../assets/crystal.png'
import custom from '../../assets/custom.png'
import body from '../../assets/body evolution.png'
import dalmore from '../../assets/dalmore.png'
// import dashi from '../../assets/dashi.png'
// import delMonte from '../../assets/del-monte.png'
import diamondBuilders from '../../assets/diamond-builders.png'
import diamondHomes from '../../assets/diamond-homes.png'
import fbr from '../../assets/fbr.png'
// import haier from '../../assets/haier.png'
import hotwav from '../../assets/hotwav.png'
// import iba from '../../assets/iba.png'
import idealGoldVista from '../../assets/ideal-gold-vista.png'
// import industries from '../../assets/industries.png'
import insignia from '../../assets/insignia.png'
// import international from '../../assets/international.png'
// import internationalSteel from '../../assets/international-steel.png'
import justmac from '../../assets/justmac.png'
// import ktdmc from '../../assets/ktdmc.png'
import lakhaniProperties from '../../assets/lakhani-properties.png'
// import langnese from '../../assets/langnese.png'
// import mehran from '../../assets/mehran.png'
import memonbuilder from '../../assets/memon-builder.png'
import moonLight from '../../assets/moon-light.png'
import morie from '../../assets/morie.png'
// import mp from '../../assets/m-p.png'
import newWorld from '../../assets/new-world.png'
// import novo from '../../assets/novo.png'
import ocean from '../../assets/ocean.png'
import orient from '../../assets/orient.png'
import oxford from '../../assets/oxford.png'
import pepsi from '../../assets/pepsi.png'
import portGrand from '../../assets/port-grand.png'
import prime from '../../assets/prime.png'
import ronin from '../../assets/ronin.png'
import royal from '../../assets/royal.png'
// import saudia from '../../assets/saudia.png'
import scrubs from '../../assets/scrubs.png'
import shaheenAir from '../../assets/shaheen-air.png'
import shell from '../../assets/shell.png'
import superPower from '../../assets/super-power.png'
// import suzuki from '../../assets/suzuki.png'
import tcs from '../../assets/tcs.png'
// import thal from '../../assets/thal.png'
import tpw from '../../assets/tpw.png'
import tpwMoulding from '../../assets/tpw-moulding.png'
// import unilever from '../../assets/unilever.png'
import vnus from '../../assets/vnus.png'
// import voldam from '../../assets/voldam.png'
// import walls from '../../assets/walls.png'
// import wwf from '../../assets/wwf.png'
import youngs from '../../assets/youngs.png'
// import zuni from '../../assets/zuni.png'
import { Helmet } from 'react-helmet';




export default class ourClient extends Component{
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render(){
        return (
          <div>
            <Helmet>
              <link
                rel="canonical"
                href="https://www.expobird.com/our-clients"
                data-react-helmet="true"
              />
              <title>Our Client | Expobird</title>
            </Helmet>
            <div className="row background-2">
              <div className="container text-center">
                <h1 id="heading-main">Our Clients</h1>
                <div className="container">
                  <Link to="/" className="linkText">
                    Home
                  </Link>{" "}
                  &#xbb;{" "}
                  <Link to="/our-clients" className="linkText">
                    Our Clients
                  </Link>
                </div>
              </div>
            </div>

            <div className="row background-3">
              <div className="container py-5">
                <div className="row">
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={AG} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={youngs} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={tcs} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={tpwMoulding} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={pepsi} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={ronin} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={royal} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={scrubs} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={shaheenAir} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={shell} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={superPower} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={portGrand} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={prime} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={moonLight} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={morie} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={newWorld} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={ocean} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={orient} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={oxford} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={diamondBuilders} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={diamondHomes} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={fbr} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={aurDekhao} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={hotwav} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={crystal} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={custom} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={dalmore} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={bahriaTown} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={aryResidency} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={atlasInsurance} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={apimatic} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={Allied} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={aliLakhani} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={agp} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={hill} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={homes} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={hut} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={jackjees} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={jewellery} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={lsn} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={moj} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={memonbuilder} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={memongroup} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={mgc} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={narkins} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={premierbuilder} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={pricetech} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={profitt} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={quick} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={rainbow} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={spot} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={world} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={tpw} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={walnut} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={westpoint} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={afabco} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={wood} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={ameeri} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={berry} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={body} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={dhanani} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={doha} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={frune} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={gaba} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={gm} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={happy} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={harbour} alt="" />
                    </div>
                  </div>
                  <div className="col-4 col-sm-4 col-md-2 col-lg-2 py-2">
                    <div className="rectangle-1 text-center">
                      <img src={herbal} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    }
}