import React from "react";
import { Link } from "react-router-dom";
import { ImArrowUp } from "react-icons/im";

const BlogTemplate = ({
  increase,
  increaseDescription,
  image,
  btnTXT,
  links,
}) => {
  return (
    <div>
      <div className="col-12 mt-5 mb-5 BlogContent-bg">
        <div className="row align-items-center">
          <div className="col-12 col-md-6 p-4 p-md-5 order-md-1 order-12">
            <div className="">
              <h4 className="text-lg-left text-center">
                Frutaz Success Story: Navigating Challenges, Harvesting Results
              </h4>
              <p className="h6 my-3 text-lg-left text-center">
                Explore our success stories and real-world applications. Dive
                into our case studies to witness the transformative impact of
                our solutions across diverse industries.
              </p>
              <div className="text-lg-left text-center">
                <a href={links}>
                  <button className="rf">{btnTXT}</button>
                </a>
              </div>
            </div>
            {/* <div className="">
                            <div className="row">
                                <div className="col-4">                               
                                    <h4 className="text-left">{increase} <span className="growUp"><ImArrowUp/></span></h4>
                                    <p className='h6'>{increaseDescription}</p>                    
                                </div>
                                <div className="col-4">                               
                                    <h4 className="text-left ">{increase}<span className="growUp"><ImArrowUp/></span></h4>
                                    <p className='h6'>{increaseDescription}</p>
                                </div>
                                <div className="col-4">                               
                                    <h4 className="text-left ">{increase}<span className="growUp"><ImArrowUp/></span></h4>
                                    <p className='h6'>{increaseDescription}</p>                    
                                </div>
                            </div>
                            <a href={links}>
                                <button className="rf">{btnTXT}</button>
                            </a>
                        </div> */}
          </div>
          <div className="text-center col-12 col-md-6  text-center order-md-12 order-1">
            <img src={image} className="CaseStudyLogo" width={300} height={300}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogTemplate;
