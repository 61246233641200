import React, { Component } from "react";
import Pentagon from "../../assets/pentagon.svg";
import { Link } from "react-router-dom";
export default class CtaOne extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.makeMuted = this.makeMuted.bind(this);
  }
  makeMuted(elt) {
    if (elt) {
      elt.muted = true;
    }
  }
  render() {
    return (
      <div className="container">
        <div className="row testi-margin align-items-center">
          <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 col-12 text-center">
            <div>
              <video
                className="slight-br ChooseImg2"
                alt="Online Marketing Agency in Karachi"
                ref={this.makeMuted}
                autoPlay
              >
                <source src={this.props.video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
          <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 col-12 mt-5 mt-sm-5 mt-md-5 mt-lg-0 mb-3 text-left">
            <div className="margin-left5  ">
              <h2 className="heading-page ">{this.props.heading} </h2>
              <h3 className="mt-4 layout-heading stats-Text">
                {this.props.subheading}{" "}
              </h3>
              <p className="py-3 p-style">{this.props.paragraph}</p>
              <Link to={this.props.link}>
                <button className="services-cta  align-self-center">
                  {this.props.button}
                </button>
              </Link>
            </div>
            <div className="pentagon">
              <img src={Pentagon} alt="" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
