import axios from "axios";
import React, { Component, useState } from "react";
import Cfvideo from "../assets/cf-video.mp4";
import $ from "jquery";
import Recaptcha from "react-recaptcha";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export default class ContactFormFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      email: "",
      marketing: "",
      contact: "",
      message: "",
      isVerified: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.recaptchaLoaded = this.recaptchaLoaded.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
    this.makeMuted = this.makeMuted.bind(this);
  }
  recaptchaLoaded() {
    console.log("loaded");
  }
  makeMuted(elt) {
    if (elt) {
      elt.muted = true;
    }
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  verifyCallback(res) {
    if (res) {
      this.setState({
        isVerified: true,
      });
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.state.isVerified) {
      $(".thankyou-message").css({
        display: "block",
      });

      axios({
        method: "post",
        url: "https://www.expobird.com/email.php",
        headers: { "content-type": "application/json" },
        data: this.state,
      })
        .then((result) => {
          console.log("here", result);
          $(".services-cta").attr("disabled", "true");
          setTimeout(() => {
            $(".thankyou-message").css({
              display: "none",
            });
          }, 2000);
          setTimeout(() => {
            window.location.href = "/thankyou";
          }, 2500);
        })
        .catch((error) => {
          this.setState({ error: error.message });
          $(".services-cta").css("opacity", "0.5");
        });
    } else {
      alert("Please verify that you are a human!");
    }
  }
  // componentDidMount(){
  //     const [Name , setName] = useState('');
  //     const HandleSubmit = (e) => {
  //         e.preventDefault();
  //         console.log(Name);
  //         setName('');
  //     }
  // }
  render() {
    return (
      <div className="container">
        <div className="thankyou-message p-3">
          Thank you! We've received your message.
        </div>
        <div className="row testi-margin">
          <div className="col-12 col-sm-12 col-md-8 col-lg-8 text-center">
            <video
              className="slight-br videoSize"
              alt="Unleashed Technology. Contact Us Now "
              ref={this.makeMuted}
              autoPlay
            >
              <source src={Cfvideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="col-12 col-sm-12 col-md-4 col-lg-4 text-left py-5 py-lg-0">
            <h4 className="mb-3" id="ah2">
              <b>Request A Callback</b>
            </h4>
            <div>
              <p className="text-secondary-center p-style">
                Send us an email if you have any questions about your work and
                of its marketing to ExpoBird
              </p>
            </div>

            <form action="email.php" method="post" onSubmit={this.handleSubmit}>
              <div className="mb-3">
                <input
                  type="text"
                  className="cf-fields"
                  placeholder="Your Name"
                  name="username"
                  value={this.state.value}
                  onChange={this.handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <input
                  type="email"
                  id=""
                  className="cf-fields"
                  placeholder="Email"
                  name="email"
                  value={this.state.value}
                  onChange={this.handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <select
                  id=""
                  name="marketing"
                  className="cf-fields"
                  required
                  value={this.state.value}
                  onChange={this.handleChange}
                >
                  <option value="">I'm interested in...</option>
                  <option value="Digital Marketing">Digital Marketing </option>
                  <option value="Graphics and Design">
                    Graphics and Design
                  </option>
                  <option value="Website Development">
                    Website Development
                  </option>
                  <option value="Video and Animation">
                    Video and Animation
                  </option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="mb-3">
                <textarea
                  name="message"
                  id=""
                  cols="30"
                  rows="3"
                  className="cf-fields"
                  required
                  value={this.state.value}
                  onChange={this.handleChange}
                ></textarea>
              </div>
              <div className="mb-3">
                {/* <input
                  type="number"
                  name="contact"
                  className="cf-fields"
                  id="inlineFormInputGroup"
                  placeholder="Contact#"
                  required
                  value={this.state.value}
                  onChange={this.handleChange}
                /> */}
                <PhoneInput
                  country={"pk"}
                  name="contact"
                  placeholder="Contact#"
                  value={this.state.contact}
                  onChange={contact => this.setState({ contact })}
                />
              </div>
              <Recaptcha
                sitekey="6Lfq9c4oAAAAAPQzYqLXUO4nmYM3WXHJOdpkXJHZ"
                render="explicit"
                onloadCallback={this.recaptchaLoaded}
                verifyCallback={this.verifyCallback}
              />

              <input type="submit" className="services-cta cf-submit m-3" />

              {/* <div>
                {this.state.mailSent && <div>Thank you for contacting us.</div>}
              </div> */}
            </form>
          </div>
        </div>
      </div>
    );
  }
}
