
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'
import ReadMoreReact from "read-more-react";
import {Link} from "react-router-dom";
export default function Services(props){
    return (
      // <div className="container">
      <div className="row ">
        <div className="col-md-1"></div>
        <div className="col-md-5 mb-5 bg-white ml-4 ml-sm-4 ml-md-0 ml-lg-0 mr-4">
          <div className="text-center text-md-left d-md-flex">
            <div className="services-video">
              <img
                alt={props.alt1}
                className="blueColor"
                src={props.image1}
                width={64}
              />
            </div>
            <div className="services-text ml-3">
              <h3 className="services-heading">{props.title1}</h3>
              <div className="p-style m-0">
                <ReadMoreReact text={props.desc1} min={80} />
              </div>
              <div>
                <Link to={props.link1}>
                  <button className="services">
                    Explore &nbsp;
                    <FontAwesomeIcon icon={faCaretRight} />
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-5 mb-5 bg-white ml-4 ml-sm-4 ml-md-0 ml-lg-0 mr-4">
          <div className="text-center text-md-left d-md-flex">
            <div className="services-video">
              <img
                alt={props.alt2}
                src={props.image2}
                className="blueColor"
                width={64}
              />
            </div>
            <div className="services-text ml-3">
              <h3 className="services-heading">{props.title2}</h3>
              <div className="p-style m-0">
                <ReadMoreReact text={props.desc2} min={80} />
              </div>
              <div>
                <Link to={props.link2}>
                  <button className="services">
                    Explore &nbsp;
                    <FontAwesomeIcon icon={faCaretRight} />
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-1"></div>
      </div>
    );}