import React from 'react';
import Main from './Main'
import './App.css';
import './Responsive.css';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import { Helmet } from 'react-helmet'

function App() {
    return (
      <div className="App" id="main">
        <Helmet>
             <meta
              name="description"
              content="Main content"/>
        </Helmet>
          <Main/>
          {/* <MessengerCustomerChat
          pageId="185506882345203"
          appId="363775251678392"
          /> */}
        </div>
  );
}

export default App;
