import $ from "jquery";
import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";

class ContactForm extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    /* $(document).ready(function(){
            $(".submitForm").click(function(){
              alert("The paragraph was clicked.");
            });
          }); */
  }

  constructor(props) {
    super(props);
    this.state = {
      username: "",
      email: "",
      contact: "",
      message: "",
      marketing: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  handleSubmit(event) {
    event.preventDefault();

    $(".thankyou-message").css({
      display: "block",
    });

    axios({
      method: "post",
      url: "https://www.expobird.com/email.php",
      headers: { "content-type": "application/json" },
      data: this.state,
    })
      .then((result) => {
        if (result.success) {
          console.log("here");
          setTimeout(() => {
            $(".thankyou-message").css({
              display: "none",
            });
          }, 2000);
          setTimeout(() => {
            this.props.history.push("/thankyou");
          }, 2500);
        }

        /* this.setState({
                mailSent: result.data.sent
            }) */
      })
      .catch((error) => this.setState({ error: error.message }));
    $(".services-cta").css("opacity", "0.5");
    $(".services-cta").attr("disabled", "true");
    $(".services-cta").attr("disabled", "true");
  }

  hack() {
    alert("asda");
  }

  render() {
    return (
      <div>
        <div className="container text-center" id="started">
          <div className="thankyou-message p-3">
            Thank you! We've received your message.
          </div>
          <form action="email.php" method="post" onSubmit={this.handleSubmit}>
            <div className="mb-3">
              <input
                type="text"
                className="cf-fields"
                placeholder="Your Name"
                name="username"
                required
                value={this.state.value}
                onChange={this.handleChange}
              />
            </div>
            <div className="mb-3">
              <input
                type="text"
                id=""
                className="cf-fields"
                placeholder="Email"
                name="email"
                required
                value={this.state.value}
                onChange={this.handleChange}
              />
            </div>
            <div className="mb-3">
              <select
                onChange={this.handleChange}
                name="marketing"
                id=""
                required
                className="cf-fields"
              >
                <option value="">I'm interested in...</option>
                <option value="Digital Marketing">Digital Marketing </option>
                <option value="Graphics and Design">Graphics and Design</option>
                <option value="Website Development">Website Development</option>
                <option value="Video and Animation">Video and Animation</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div className="mb-3">
              <textarea
                id=""
                onChange={this.handleChange}
                cols="30"
                rows="3"
                required
                className="cf-fields"
                name="message"
              ></textarea>
            </div>
            <div className="mb-3">
              <input
                type="text"
                onChange={this.handleChange}
                id=""
                name="contact"
                required
                placeholder="Your Contact#"
                className="cf-fields"
              />
            </div>
            <input
              type="submit"
              name="submit"
              id=""
              className="services-cta cf-submit"
              data-action="submit"
            />
          </form>
        </div>
      </div>
    );
  }
}
export default withRouter(ContactForm);