import React,{Component } from 'react';
import { BrowserRouter as Router, Switch,Route} from "react-router-dom";
import Navbar from './components/Navbar'
import Homepage from './components/homepage'
import DigitalMarketing from './components/pages/digitalMarketing'
import VideoProduction from './components/videoProduction'
import WebDevelopment from './components/pages/WebDevelopment'
import Animation from './components/pages/Animation'
import GraphicDesign from './components/pages/GraphicDesign'
import ContactUs from './components/pages/Contact-Us'
import AboutUs from './components/pages/About-Us'
import CaseStudy from './components/pages/CaseStudyListing'
import Footer from './components/Footer'
import Amazon from './components/amazon';
import { Redirect } from 'react-router-dom';
 import Client from './components/pages/our-client';
 import SocialMediaMarketing from './components/pages/socialMediaMarketing';
 import Seo from './components/pages/Seo';
 import ThankYou from './components/pages/ThankYou'
import Privacy from './components/pages/privacy';
import DataLeakage from './components/pages/data_leakage';
import Careers from './components/pages/Careers';
import FrutazCaseStudy from './components/pages/frutaz-case-study';
import HappyCaseStudy from './components/pages/happyhouse-case-study';
import WestpointCaseStudy from './components/pages/westpoint-case-study';

export default class Main extends Component{
    render(){
        return (
          <Router>
            <div>
              {/* <HelmetWrapper title={"Expobird"} description={"This is main Home page"}/> */}
              <Navbar />
              <Switch>
                <Route path="/" exact component={Homepage} />
                {/* <Route path="/DIGITAL-MARKETIN-IN-COVID-19" 
                            component={DIGITALMARKETINGINCOVID}
                        />
                         */}
                <Route path="/case-study" component={CaseStudy} />
                <Route path="/amazon-services" component={Amazon} />
                {/* <Route path="/ebay-services"
                            component={Ebay}
                        /> */}
                {/* <Route path="/alibaba-services"
                            component={AliBaba}
                        /> */}
                {/* <Route path="/walmart-services"
                            component={Walmart}
                        /> */}

                {/* <Route path="/instagram-marketing"
                            component={InstagramMarketing}
                        />                         */}

                {/* <Route path="/Hilton-Pharma"
                            component={HiltonPharma}
                        />
                         */}
                <Route path="/digital-marketing" component={DigitalMarketing} />

                <Route path="/search-engine-optimization" component={Seo} />

                <Route
                  path="/social-media-marketing"
                  component={SocialMediaMarketing}
                />

                <Route
                  path="/video-production-service"
                  component={VideoProduction}
                />

                {/* <Route path="/digital-video-commercial"
                            component={DigitalVideoCommercial}
                            /> */}

                <Route path="/web-development" component={WebDevelopment} />

                <Route path="/animation" component={Animation} />

                <Route path="/our-clients" component={Client} />

                <Route path="/graphic-design" component={GraphicDesign} />

                {/* <Route path="/event-management"
                            component={EventManagement}
                            /> */}

                <Route path="/contact-us" component={ContactUs} />
                <Route path="/careers" component={Careers} />
                <Route path="/about-us" component={AboutUs} />

                <Route path="/frutaz-case-study"
                            component={FrutazCaseStudy}
                            />

                <Route path="/happyhouse-case-study"
                            component={HappyCaseStudy}
                            />

                <Route path="/westpoint-case-study"
                            component={WestpointCaseStudy}
                            /> 
                <Route path="/thankyou" component={ThankYou} />
                <Route path="/privacy-policy" component={Privacy} />
                <Route
                  path="/data-leakage-prevention-policy"
                  component={DataLeakage}
                />
                <Redirect from="*" to="/" />
              </Switch>
              <Footer />
            </div>
          </Router>
        );
    }
}