import React, { Component } from "react";
import OxfordBanner from "../../assets/diamond homes cover.jpg";
import diamondHomes from "../../assets/diamond homes.png";
import AnimatePopup from "../animatePopup";
import ContactForm from "./ContactForm";
import Ctafifth from "./cta-five";
import NextBlogImg from "../../assets/blog_img_05.jpg";
import MoreCaseTemplate from "./MoreCaseStudyTemplate";
import HappyHeroImg from "../../assets/happy-hero-img.png";
import Happyroas from "../../assets/happy-roas.png";
import Happysales from "../../assets/happy-sales.png";
import HappyAbout from "../../assets/happy-about.png";
import frutazLaptop from "../../assets/frutaz-laptop.png";
import frutazLaptop2 from "../../assets/frutaz-laptop02.png";
import happyGoogle from "../../assets/happy-google.png";
import happyLaptop from "../../assets/happy-laptop.png";
import happyLaptopSale from "../../assets/happy-laptop-sale.png";
import frutazMobile1 from "../../assets/frutaz-mobile01.png";
import frutazMobile2 from "../../assets/frutaz-mobile02.png";
import frutazMobile3 from "../../assets/frutaz-mobile03.png";
import HappyBlue from "../../assets/happy-blue-image.png";
import HappyYellow from "../../assets/happy-yellow-image.png";
import { scrollText } from '../../your-script';

export default class HappyCaseStudy extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    scrollText();
  }
  render() {
    return (
      <div className="">
        <div className="row background-2">
          <div className="container text-center px-0">
            <h1 id="heading-main">Happy House Case study</h1>
          </div>
        </div>
        <div className="container-md px-0">
          <div className="p-md-5 p-2 mx-0 position-relative">
            {/* <img src={NextBlogImg} /> */}
            <img
              src={HappyHeroImg}
              className="max-h-800px img-fluid"
              data-aos="zoom-in"
              data-aos-duration="900"
              alt=""
            />
            {/* <div className="inline-flex items-start absolute left-2/4 -translate-x-2/4 top-12">
            <span data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="100">
                <svg width="43" height="43" className="inline" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M34.9278 8.01697C31.441 4.56504 26.6247 2.43016 21.3038 2.43016C15.9834 2.43016 11.1665 4.56504 7.6802 8.01697C4.19342 11.4689 2.03675 16.2374 2.03675 21.5046C2.03675 26.7723 4.19342 31.5408 7.6802 34.9928C11.167 38.4447 15.9834 40.5796 21.3038 40.5796C26.6242 40.5796 31.441 38.4447 34.9278 34.9928C38.4141 31.5408 40.5708 26.7723 40.5708 21.5046C40.5708 16.2374 38.4141 11.4689 34.9278 8.01697ZM12.028 19.7886C11.4443 16.3425 10.8611 12.8964 10.278 9.44975C11.1724 9.44975 12.3397 9.44975 13.2342 9.44975C14.0047 11.2631 14.8396 13.0145 16.5788 14.6477C18.1169 13.3467 18.7576 11.4704 19.2626 9.50737C20.5947 9.50737 21.9273 9.50737 23.2593 9.50737C23.7643 11.4704 24.405 13.3467 25.9431 14.6477C27.6828 13.014 28.5173 11.2631 29.2878 9.44975C30.1827 9.44975 31.3496 9.44975 32.244 9.44975C31.6604 12.8959 31.0772 16.342 30.494 19.7886C29.3272 19.7886 28.1603 19.7886 26.9935 19.7886C24.0869 17.0083 18.4474 16.9966 15.529 19.7886C14.3617 19.7886 13.1948 19.7886 12.028 19.7886ZM12.0432 21.247H30.4783V25.2267H12.0432V21.247ZM12.0432 26.1516H30.4783V30.1313H12.0432V26.1516ZM12.0432 31.0561H30.4783V35.0359H12.0432V31.0561ZM21.3038 0.61438C27.1301 0.61438 32.4058 2.95263 36.224 6.73285C40.0427 10.5131 42.4044 15.7358 42.4044 21.5041C42.4044 27.2725 40.0422 32.4952 36.224 36.2759C32.4058 40.0566 27.1306 42.3949 21.3038 42.3949C15.4769 42.3949 10.2017 40.0566 6.38354 36.2764C2.56485 32.4957 0.203125 27.2735 0.203125 21.5046C0.203125 15.7362 2.56485 10.5136 6.38354 6.73333C10.2022 2.95311 15.4774 0.61438 21.3038 0.61438Z" fill="white"/>
                </svg>
            </span>
            <span className="text-[43px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="200">W</span>
            <span className="text-[43px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="300">E</span>
            <span className="text-[43px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="400">S</span>
            <span className="text-[43px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="500">T</span>
            <span className="text-[43px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="600">P</span>
            <span className="text-[43px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="700">O</span>
            <span className="text-[43px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="800">I</span>
            <span className="text-[43px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="900">N</span>
            <span className="text-[43px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="1000">T</span>
            <span
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="1100">
                <svg width="9" height="9" className="inline" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.18348 4.37231C5.41016 4.26723 5.59603 4.12052 5.73765 3.93507C5.91024 3.71039 5.99875 3.42761 5.99875 3.09593C5.99875 2.85818 5.94958 2.65336 5.8532 2.48776C5.75732 2.32459 5.62603 2.18852 5.46278 2.08587C5.3015 1.98177 5.12791 1.91398 4.94794 1.88638C4.77682 1.85926 4.56442 1.8457 4.3161 1.8457H3.10352V6.2675H3.89567V4.59795H4.37608L5.72783 6.2675H6.7565L5.18348 4.37231ZM5.18004 3.13709C5.18004 3.2717 5.16234 3.38791 5.12693 3.48427C5.09448 3.57724 5.04432 3.65519 4.97253 3.72395C4.89582 3.79755 4.80486 3.84549 4.6957 3.8697C4.57129 3.89585 4.43214 3.9094 4.28167 3.9094H3.89567V2.54587H4.34412C4.49557 2.54587 4.62244 2.55652 4.7198 2.57541C4.81224 2.59429 4.89189 2.62577 4.96122 2.67177C5.03547 2.7197 5.0881 2.78023 5.12449 2.8577C5.16186 2.9376 5.18004 3.03153 5.18004 3.13709Z" fill="white"/>
                <path d="M4.7483 8.01159C2.53359 8.01159 0.731445 6.23696 0.731445 4.05512C0.731445 1.87424 2.53359 0.0996094 4.7483 0.0996094C6.963 0.0996094 8.76517 1.87424 8.76517 4.05512C8.76517 6.23696 6.963 8.01159 4.7483 8.01159ZM4.7483 0.766366C2.90632 0.766366 1.40806 2.24175 1.40806 4.0556C1.40806 5.86945 2.90632 7.3458 4.7483 7.3458C6.59027 7.3458 8.08855 5.86945 8.08855 4.0556C8.08855 2.24175 6.59027 0.766366 4.7483 0.766366Z" fill="white"/>
                </svg>
            </span>
        </div>
        <div className="inline-flex items-start absolute left-2/4 -translate-x-2/4 top-32">
            <span className="text-[70px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="1300">W</span>
            <span className="text-[70px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="1400">E</span>
            <span className="text-[70px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="1500">S</span>
            <span className="text-[70px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="1600">T</span>
            <span className="text-[70px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="1700">P</span>
            <span className="text-[70px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="1800">O</span>
            <span className="text-[70px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="1900">I</span>
            <span className="text-[70px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="2000">N</span>
            <span className="text-[70px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="2100">T</span>
            &nbsp;
            <span className="text-[70px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="2200">P</span>
            <span className="text-[70px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="2300">A</span>
            <span className="text-[70px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="2400">K</span>
            <span className="text-[70px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="2500">I</span>
            <span className="text-[70px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="2600">S</span>
            <span className="text-[70px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="2700">T</span>
            <span className="text-[70px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="2800">A</span>
            <span className="text-[70px] leading-none text-white font-medium"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="2900">N</span>
        </div>
        <div className="inline-flex items-start absolute left-2/4 -translate-x-2/4 top-52">
            <p className="font-medium text-[24px] text-white"
            data-aos="fade-up"
            data-aos-anchor-placement="center-center" data-aos-delay="3000">Best Kitchen Appliances Provider in Pakistan</p>
        </div> */}
          </div>
        </div>
        <div className="bg-FFFFFF my-md-5 py-5 wavy-bg">
          <div className="p-md-5 p-2 d-flex gap-20 flex-md-row flex-column">
            <div className="d-inline-flex align-items-center justify-content-md-start justify-content-center gap-8">
              <img src={Happyroas} className="img-fluid" alt="" />
              <div>
                <p className="leading-none font-weight-bolder h1 mb-0 text-151515">
                  619%
                </p>
                <p className="h4 mb-0 leading-none text-151515">ROAS</p>
              </div>
            </div>
            {/* <div className="d-inline-flex align-items-center justify-content-md-start justify-content-center gap-8">
              <img src={Happysales} className="img-fluid" alt="" />
              <div>
                <p className="leading-none text-white font-weight-bolder h1 mb-0">
                  800%
                </p>
                <p className="text-white h4 leading-none mb-0">
                  Return on Investment
                </p>
              </div>
            </div> */}
          </div>
          <div className="border-md-top border-md-bottom border-md-dark mb-md-5">
            <div className="pd">
              <div className="scroll-header">
                <p
                  className="scroll-text text-200px text-151515 font-weight-bolder"
                  data-inviewport
                >
                  +150% Increase
                </p>
              </div>

              <div className="scroll-header">
                <p
                  className="scroll-text text-200px text-151515 font-weight-bolder"
                  data-inviewport
                >
                  in Revenue
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="py-5">
          <div className="max-w-600px mx-auto text-center pb-5">
            <h6
              className="text-151515 font-weight-bolder display-4"
              data-aos="zoom-in"
              data-aos-duration="900"
            >
              About HappyHouse
            </h6>
            <p className="text-A1A1A1 h6">
              HappyHouse is committed to providing high-quality kitchen and
              household accessories at economical prices for customers while
              maintaining the highest integrity and customer service standards.
              The company was established in 1991 and has served its valuable
              customers for over 31 years. Happy House is Pakistan's best
              one-stop shop providing a wide range of products such as Kitchen
              Coolers, Thermo ware, Casseroles, Insulated Plastic Products,
              Plastic Houseware, Food Warmer, Hot Pots, and many more.
            </p>
          </div>
          <div className="bg-000000">
            <div className="px-5 pt-5 text-center">
              <img src={HappyAbout} className="img-fluid" alt="" />
            </div>
          </div>
        </div>

        <div class="px-5 py-5 bg-F9F9F9">
          <div class="d-flex flex-wrap">
            <div className="col">
              <div class="d-inline-flex items-center pb-4">
                <svg
                  width="65"
                  height="84"
                  viewBox="0 0 65 84"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.000958083 41.9059C0.000958083 31.031 -0.00197727 20.156 0.00242791 9.2811C0.0053647 3.59107 3.06991 0.517714 8.74673 0.516246C24.3558 0.51184 39.9648 0.639594 55.5709 0.445766C60.9482 0.378219 64.722 3.95082 64.6529 9.59093C64.4474 26.0928 64.5619 42.5975 64.6104 59.1023C64.6148 60.578 64.1566 61.6044 63.1243 62.625C56.6194 69.0551 50.1496 75.5233 43.721 82.0313C42.6446 83.1223 41.5257 83.5628 40.0015 83.5555C29.5744 83.5041 19.1474 83.532 8.72029 83.5261C3.03026 83.5232 0.00389631 80.4645 0.00242791 74.7216C-0.00197727 63.7835 0.000958083 52.844 0.000958083 41.9059ZM39.9398 80.394C39.9398 76.1019 39.9384 72.0785 39.9398 68.0551C39.9413 61.8262 42.862 58.8894 49.0586 58.8879C53.1334 58.8864 57.2082 58.8879 61.446 58.8879C61.446 58.1126 61.446 57.5502 61.446 56.9863C61.446 41.1805 61.4489 25.3732 61.4445 9.56744C61.443 5.32377 59.7632 3.6498 55.5107 3.64834C39.9604 3.64393 24.4101 3.64246 8.85979 3.64834C4.89219 3.6498 3.14039 5.39279 3.13892 9.3927C3.13011 31.2791 3.13158 53.1641 3.13745 75.0505C3.13892 78.604 4.90099 80.3793 8.42661 80.3881C14.5058 80.4028 20.5849 80.3925 26.6656 80.3925C31.0047 80.394 35.3467 80.394 39.9398 80.394ZM43.0896 77.5673C48.3082 72.3516 53.4432 67.221 58.6061 62.0596C54.8367 62.0596 50.9602 61.9554 47.0924 62.1007C44.9412 62.1815 43.1923 64.0934 43.1219 66.3532C43.0029 70.1094 43.0896 73.8729 43.0896 77.5673Z"
                    fill="#FF4629"
                  />
                  <path
                    d="M32.3704 12.8038C37.2293 12.8038 42.0897 12.7891 46.9486 12.8082C50.4963 12.8228 52.2495 14.5761 52.2877 18.0885C52.298 18.9828 52.3391 19.8829 52.2657 20.7727C52.066 23.2323 50.2129 25.106 47.7489 25.1177C37.454 25.1662 27.1605 25.1735 16.8656 25.128C14.271 25.1162 12.4428 23.2132 12.3048 20.6318C12.2505 19.6112 12.2593 18.5848 12.2989 17.5628C12.409 14.6965 14.315 12.8287 17.2166 12.8111C22.2679 12.7832 27.3191 12.8052 32.3704 12.8038ZM32.1648 22.0209C36.896 22.0209 41.6272 22.0267 46.3569 22.0179C48.8634 22.0135 49.1542 21.6993 49.1571 19.0914C49.16 16.1943 48.9515 15.9402 46.4068 15.9388C36.9445 15.9314 27.4836 15.9314 18.0213 15.9388C15.7085 15.9402 15.4295 16.2692 15.4222 18.855C15.4149 21.6919 15.6454 22.0047 17.9713 22.0135C22.7025 22.0326 27.4337 22.0209 32.1648 22.0209Z"
                    fill="#FF4629"
                  />
                  <path
                    d="M23.0886 63.556C21.1078 63.556 19.1254 63.5868 17.146 63.5486C14.2269 63.4914 12.3796 61.6485 12.2959 58.747C12.268 57.7896 12.2548 56.8278 12.3003 55.8719C12.4325 53.1157 14.337 51.245 17.1167 51.2185C21.0784 51.1803 25.0401 51.1745 29.0019 51.2259C31.9401 51.264 33.7565 53.1113 33.8476 56.0158C33.8667 56.6545 33.8579 57.2933 33.8564 57.932C33.8461 61.8189 32.1398 63.5369 28.2662 63.5545C26.5394 63.5648 24.814 63.5574 23.0886 63.556ZM22.886 54.3418C21.0975 54.3418 19.309 54.3697 17.522 54.333C16.0682 54.3036 15.3987 54.9849 15.4222 56.4137C15.4339 57.1156 15.4412 57.819 15.4222 58.5208C15.3869 59.8248 16.1167 60.4019 17.3105 60.4092C21.1415 60.4356 24.9741 60.4312 28.8051 60.4136C30.1458 60.4077 30.7698 59.6765 30.7243 58.3417C30.7037 57.7044 30.6979 57.0642 30.7243 56.4254C30.7845 54.9585 30.043 54.33 28.6318 54.3389C26.7185 54.3506 24.8023 54.3433 22.886 54.3418Z"
                    fill="#FF4629"
                  />
                  <path
                    d="M32.2896 31.238C38.1147 31.238 43.9384 31.2395 49.7635 31.2366C50.8883 31.2366 52.1614 31.2278 52.2143 32.705C52.2715 34.304 50.9764 34.3731 49.7591 34.3731C38.1103 34.3687 26.4615 34.3716 14.8113 34.3672C13.7041 34.3672 12.4178 34.398 12.3664 32.8944C12.3121 31.2762 13.5911 31.2292 14.8142 31.2322C20.6408 31.2439 26.4659 31.238 32.2896 31.238Z"
                    fill="#FF4629"
                  />
                  <path
                    d="M32.2896 40.4551C38.1147 40.4551 43.9384 40.4565 49.7635 40.4536C50.8883 40.4536 52.1614 40.4448 52.2143 41.922C52.2715 43.5211 50.9764 43.5901 49.7591 43.5901C38.1103 43.5857 26.4615 43.5886 14.8113 43.5842C13.7041 43.5842 12.4178 43.6151 12.3664 42.1114C12.3121 40.4933 13.5911 40.4463 14.8157 40.4492C20.6408 40.4609 26.4659 40.4551 32.2896 40.4551Z"
                    fill="#FF4629"
                  />
                </svg>
                <h6 class="text-151515 font-weight-bolder h1 ml-4 display-4">
                  Mission
                </h6>
              </div>
              <p class="text-A1A1A1 max-w-480px h6">
                To address all consumer requirements with a focus on customer
                satisfaction, quality, and social compliance while keeping the
                products affordable and cost-effective for all consumers.
              </p>
            </div>
            <div className="col">
              <div class="d-inline-flex align-items-center pb-4">
                <svg
                  class="d-inline-block"
                  width="65"
                  height="84"
                  viewBox="0 0 65 84"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.000958083 41.9059C0.000958083 31.031 -0.00197727 20.156 0.00242791 9.2811C0.0053647 3.59107 3.06991 0.517714 8.74673 0.516246C24.3558 0.51184 39.9648 0.639594 55.5709 0.445766C60.9482 0.378219 64.722 3.95082 64.6529 9.59093C64.4474 26.0928 64.5619 42.5975 64.6104 59.1023C64.6148 60.578 64.1566 61.6044 63.1243 62.625C56.6194 69.0551 50.1496 75.5233 43.721 82.0313C42.6446 83.1223 41.5257 83.5628 40.0015 83.5555C29.5744 83.5041 19.1474 83.532 8.72029 83.5261C3.03026 83.5232 0.00389631 80.4645 0.00242791 74.7216C-0.00197727 63.7835 0.000958083 52.844 0.000958083 41.9059ZM39.9398 80.394C39.9398 76.1019 39.9384 72.0785 39.9398 68.0551C39.9413 61.8262 42.862 58.8894 49.0586 58.8879C53.1334 58.8864 57.2082 58.8879 61.446 58.8879C61.446 58.1126 61.446 57.5502 61.446 56.9863C61.446 41.1805 61.4489 25.3732 61.4445 9.56744C61.443 5.32377 59.7632 3.6498 55.5107 3.64834C39.9604 3.64393 24.4101 3.64246 8.85979 3.64834C4.89219 3.6498 3.14039 5.39279 3.13892 9.3927C3.13011 31.2791 3.13158 53.1641 3.13745 75.0505C3.13892 78.604 4.90099 80.3793 8.42661 80.3881C14.5058 80.4028 20.5849 80.3925 26.6656 80.3925C31.0047 80.394 35.3467 80.394 39.9398 80.394ZM43.0896 77.5673C48.3082 72.3516 53.4432 67.221 58.6061 62.0596C54.8367 62.0596 50.9602 61.9554 47.0924 62.1007C44.9412 62.1815 43.1923 64.0934 43.1219 66.3532C43.0029 70.1094 43.0896 73.8729 43.0896 77.5673Z"
                    fill="#FF4629"
                  />
                  <path
                    d="M32.3704 12.8038C37.2293 12.8038 42.0897 12.7891 46.9486 12.8082C50.4963 12.8228 52.2495 14.5761 52.2877 18.0885C52.298 18.9828 52.3391 19.8829 52.2657 20.7727C52.066 23.2323 50.2129 25.106 47.7489 25.1177C37.454 25.1662 27.1605 25.1735 16.8656 25.128C14.271 25.1162 12.4428 23.2132 12.3048 20.6318C12.2505 19.6112 12.2593 18.5848 12.2989 17.5628C12.409 14.6965 14.315 12.8287 17.2166 12.8111C22.2679 12.7832 27.3191 12.8052 32.3704 12.8038ZM32.1648 22.0209C36.896 22.0209 41.6272 22.0267 46.3569 22.0179C48.8634 22.0135 49.1542 21.6993 49.1571 19.0914C49.16 16.1943 48.9515 15.9402 46.4068 15.9388C36.9445 15.9314 27.4836 15.9314 18.0213 15.9388C15.7085 15.9402 15.4295 16.2692 15.4222 18.855C15.4149 21.6919 15.6454 22.0047 17.9713 22.0135C22.7025 22.0326 27.4337 22.0209 32.1648 22.0209Z"
                    fill="#FF4629"
                  />
                  <path
                    d="M23.0886 63.556C21.1078 63.556 19.1254 63.5868 17.146 63.5486C14.2269 63.4914 12.3796 61.6485 12.2959 58.747C12.268 57.7896 12.2548 56.8278 12.3003 55.8719C12.4325 53.1157 14.337 51.245 17.1167 51.2185C21.0784 51.1803 25.0401 51.1745 29.0019 51.2259C31.9401 51.264 33.7565 53.1113 33.8476 56.0158C33.8667 56.6545 33.8579 57.2933 33.8564 57.932C33.8461 61.8189 32.1398 63.5369 28.2662 63.5545C26.5394 63.5648 24.814 63.5574 23.0886 63.556ZM22.886 54.3418C21.0975 54.3418 19.309 54.3697 17.522 54.333C16.0682 54.3036 15.3987 54.9849 15.4222 56.4137C15.4339 57.1156 15.4412 57.819 15.4222 58.5208C15.3869 59.8248 16.1167 60.4019 17.3105 60.4092C21.1415 60.4356 24.9741 60.4312 28.8051 60.4136C30.1458 60.4077 30.7698 59.6765 30.7243 58.3417C30.7037 57.7044 30.6979 57.0642 30.7243 56.4254C30.7845 54.9585 30.043 54.33 28.6318 54.3389C26.7185 54.3506 24.8023 54.3433 22.886 54.3418Z"
                    fill="#FF4629"
                  />
                  <path
                    d="M32.2896 31.238C38.1147 31.238 43.9384 31.2395 49.7635 31.2366C50.8883 31.2366 52.1614 31.2278 52.2143 32.705C52.2715 34.304 50.9764 34.3731 49.7591 34.3731C38.1103 34.3687 26.4615 34.3716 14.8113 34.3672C13.7041 34.3672 12.4178 34.398 12.3664 32.8944C12.3121 31.2762 13.5911 31.2292 14.8142 31.2322C20.6408 31.2439 26.4659 31.238 32.2896 31.238Z"
                    fill="#FF4629"
                  />
                  <path
                    d="M32.2896 40.4551C38.1147 40.4551 43.9384 40.4565 49.7635 40.4536C50.8883 40.4536 52.1614 40.4448 52.2143 41.922C52.2715 43.5211 50.9764 43.5901 49.7591 43.5901C38.1103 43.5857 26.4615 43.5886 14.8113 43.5842C13.7041 43.5842 12.4178 43.6151 12.3664 42.1114C12.3121 40.4933 13.5911 40.4463 14.8157 40.4492C20.6408 40.4609 26.4659 40.4551 32.2896 40.4551Z"
                    fill="#FF4629"
                  />
                </svg>
                <h6 class="text-151515 font-weight-bolder h1 ml-4 display-4">
                  Brief
                </h6>
              </div>
              <p class="text-A1A1A1 max-w-480px h6">
                HappyHouse struggled to scale its retail business unit. They
                needed a website where they could get a massive flow of
                customers. They realised that digitising their business would
                benefit them in the long run. HappyHouse wanted to revamp its
                brand identity, which included Social Media Marketing Services,
                SEO services, Performance marketing, and collaboration with
                marketplaces while keeping the target audience in mind so that
                they would receive a satisfactory return on investment.
              </p>
            </div>
          </div>
        </div>

        <div className="px-5 d-flex align-items-center justify-content-center flex-column">
          <div className="max-w-850px py-md-5">
            <h6 className="text-151515 font-weight-bolder h1 leading-none pb-3 display-2 text-center text-lg-left">
              Challenges Identified by ExpoBird
            </h6>
            <p className="text-A1A1A1 h5 py-3 text-center text-lg-left">
              "We are expecting the ExpoBird team to provide us with eCommerce
              services which will bring more engagement and sales to our
              website." they said
            </p>
            <p className="text-A1A1A1 h5 py-3 text-center text-lg-left">
              When the client approached us, they needed 360-degree eCommerce
              services. They needed help finding the right tone of brand voice
              and identity for the company's online presence. On top of that,
              HappyHouse was facing some challenges regarding market penetration
              and brand positioning.
            </p>
            <ul className="pl-lg-3 py-lg-3 py-1 text-center text-lg-left">
              <li className="text-A1A1A1 h5 list-disc">
                Design and develop their ecommerce website
              </li>
              <li className="text-A1A1A1 h5 list-disc">
                Establish and maintain their online presence
              </li>
              <li className="text-A1A1A1 h5 list-disc">
                Maintain their social media presence to engage customers
              </li>
              <li className="text-A1A1A1 h5 list-disc">
                Collaborate with top marketpla ces to grow brand visibility
              </li>
              <li className="text-A1A1A1 h5 list-disc">
                Rank their products on top of search engines
              </li>
            </ul>
          </div>
          <div className="d-flex gap-16 pb-5 flex-lg-row flex-column">
            <div
              className="bg-FF5C46 rounded-30 h-fit"
              data-aos="fade-right"
              data-aos-duration="900"
            >
              <div className="p-lg-5 p-2">
                <h6 className="text-white font-weight-bolder display-4 text-center">
                  Our Ranking
                </h6>
                <p className="text-white h5 text-center">Methodology</p>
              </div>
              <div className="px-lg-5 pb-3">
                <img src={frutazLaptop} className="mx-auto img-fluid" alt="" />
              </div>
            </div>
            <div
              className="bg-FF3023 rounded-30 mt-48"
              data-aos="fade-left"
              data-aos-duration="900"
            >
              <div className="p-lg-5 p-2">
                <h6 className="text-white font-weight-bolder display-4 text-center">
                  5.9M+
                </h6>
                <p className="text-white h5 text-center">Revenue in 2022</p>
              </div>
              <div className="px-lg-5 pb-3">
                <img src={happyGoogle} className="mx-auto img-fluid" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex py-lg-5 flex-column flex-lg-row">
          <div className="col d-flex justify-content-center align-items-center p-lg-5 p-2 bg-F8B123 rounded-r-30">
            <img src={happyLaptopSale} className="img-fluid" alt="" />
          </div>
          <div className="p-lg-5 p-2 col">
            <h6 className="text-151515 font-weight-bolder display-4 text-center text-lg-left">
              The strategy
            </h6>
            <p className="py-3 text-ACACAC h5 text-center text-lg-left">
              After an initial kick-off meeting, we conducted extensive market
              research to better understand the client's specific marketing
              goals, which enabled us to formulate and present a solid plan.
              Developing the website was the first step in engaging our target
              audience and to sell our products. After the findings, we gave the
              client our SEO plan. Once the keywords of interest were confirmed,
              we made a content roadmap and a plan with tasks to be done month
              by month.
            </p>
            <p className="py-3 text-ACACAC h5 text-center text-lg-left">
              After that we designed and implemented a comprehensive performance
              marketing plan which included meta ads, google ads, youtube ads,
              influencer marketing and social media management to engage and
              drive potential customers to their online store which will
              eventually result in sales.
            </p>
          </div>
        </div>
        <div class="px-5 py-5">
          <div class="d-flex flex-wrap">
            <div class="d-flex col-md-6 col-12 flex-wrap px-0 gap-8 justify-content-evenly">
              <div class="bg-F9F9F9 rounded-30 d-flex align-items-center justify-content-center col-5 px-0">
                <div class="text-center">
                  <p class="text-A1A1A1 h5">Total Sales Generated</p>
                  <p class="text-151515 font-weight-bolder display-4">5.9M+</p>
                </div>
              </div>
              <div class="bg-F9F9F9 rounded-30 d-flex align-items-center justify-content-center col-5 px-0">
                <div class="text-center">
                  <p class="text-A1A1A1 h5">
                    Total Orders Received on the Website
                  </p>
                  <p class="text-151515 font-weight-bolder display-4">1759</p>
                </div>
              </div>
              <div class="bg-F9F9F9 rounded-30 d-flex align-items-center justify-content-center col-5 px-0">
                <div class="text-center">
                  <p class="text-A1A1A1 h5">Meta Ad spent</p>
                  <p class="text-151515 font-weight-bolder display-4">
                    9.6 lacs
                  </p>
                </div>
              </div>
              <div class="bg-F9F9F9 rounded-30 d-flex align-items-center justify-content-center col-5 px-0">
                <div class="text-center">
                  <p class="text-A1A1A1 h5">People Reach</p>
                  <p class="text-151515 font-weight-bolder display-4">1M</p>
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-center col-md-6 col-12 px-0">
              <img src={Happysales} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
        <div class="px-5 py-5">
          <div class="d-flex">
            <div class="text-center col">
              <h6 class="text-151515 font-weight-bolder display-4 leading-none">
                Business Impact
              </h6>
            </div>
            <div className="col">
              <p class="text-A1A1A1 h5 pb-3">
                After the update, it took about a couple of months to see the
                results. Their website received a page-one rating for the most
                generic keywords in their business niche resulting in a large
                flow of organic traffic. The Social Media management helped to
                engage potential customers, which resulted in more brand
                recognition and conversion ratio. The overall strategy presented
                by ExpoBird helped to increase conversion rates by over 120%
              </p>
            </div>
          </div>
        </div>
        <div class="px-24 py-12">
          <div class="d-flex">
            <div class="text-center col">
              <h6 class="text-151515 font-weight-bolder display-4 leading-none">
                The Result
              </h6>
            </div>
            <div className="col">
              <p class="text-A1A1A1 h5">
                +150% Increase in Revenue
              </p>
              <p class="text-A1A1A1 h5">
                +20% Traffic Increase YOY
              </p>

              <p class="text-A1A1A1 h5">
                +225% Increase in Organic Traffic
              </p>

              <p class="text-A1A1A1 h5 pb-3">
                +325% Increase in Social Media Engagement
              </p>
            </div>
          </div>
        </div>
        <div className="p-lg-5 p-2">
          <div className="d-flex align-items-center justify-content-around flex-column flex-lg-row">
            <div className="bg-95BFFC rounded-30 max-w-600px max-h-500px">
              <img src={HappyBlue} className="p-md-5 p-3 img-fluid" alt="" />
            </div>
            <div className="bg-FFB800 rounded-30 max-w-600px max-h-500px mt-3 mt-lg-0">
              <img src={HappyYellow} className="p-md-5 p-3 img-fluid" alt="" />
            </div>
          </div>
        </div>
        {/* <div className="container">
                    <div className="row pt-4 pt-sm-5">
                        <div className="col-12 col-lg-6 px-1 px-md-3 order-12 order-sm-12 order-md-12 order-lg-1 order-xl-1">
                            <div className="p-3">
                                <h2 className="BlogPage-Header mt-5">Challenges</h2>
                                <p className="BlogPage-Text pt-4 pb-2">
                                To create a lethally fast Progressive Web Application, a website that could work even without internet where there is high ratio of internet signal interruptions.
                                </p>
                                <p className="BlogPage-Text pb-4">
                                Creating an elegant website with super UI/UX is something that can be done by a lot of companies, but making this website run without internet is something that only few companies could do. This is something that has never been done before in Pakistan by any builder and developer.
                                </p> */}
        {/* <ul className="text-center text-md-left">
                                    <li>
                                        <p className="BlogPage-Text">
                                            <span className="mr-4">Icon</span>
                                            One design for your desktop, tab and mobile.
                                        </p>
                                    </li>
                                    <li>
                                        <p className="BlogPage-Text">
                                            <span className="mr-4">Icon</span>
                                            One design for your desktop, tab and mobile.
                                        </p>
                                    </li>
                                    <li>
                                        <p className="BlogPage-Text">
                                            <span className="mr-4">Icon</span>
                                            One design for your desktop, tab and mobile.
                                        </p>
                                    </li>
                                </ul> */}
        {/* </div>
                        </div>
                        <div className="col-12 col-lg-6 px-1 px-md-5 mt-5 order-1 order-sm-1 order-md-1 order-lg-12 order-xl-12">
                            <div className="NextBlog-Img mb-3 mt-5">
                                <img src={NextBlogImg}/>
                            </div>
                        </div>
                        </div> */}
        {/* <div className="row pt-4 pt-sm-5">
                        <div className="col-12 col-lg-6 px-1 px-md-5 mt-5">
                            <div className="NextBlog-Img mb-3 mt-5">
                                <img src={NextBlogImg}/>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 px-1 px-md-3">
                            <div className="p-3">
                                <h2 className="BlogPage-Header mt-5">Insights</h2>
                                <p className="BlogPage-Text pt-4 pb-2">
                                Diamond Homes is a very renowned name in the field of Builders and Developers. With their long tale of successful projects, their next target was to capture the market of newly and state-of-art developed Bahria Town Karachi.
                                </p>
                                <p className="BlogPage-Text pb-4">
                                Since Bahria Town Karachi is located on main National Highway, the internet signals and mobile networks disruption is normal thing. Keeping this problem in mind, ExpoBird had to go out of the box and come up with the solution that not only satisfied Diamond Homes’ requirements of fast and responsive website, but also it should be able to work when there is no internet.
                                </p> */}
        {/* <ul className="text-center text-md-left">
                                    <li>
                                        <p className="BlogPage-Text">
                                            <span className="mr-4">Icon</span>
                                            One design for your desktop, tab and mobile.
                                        </p>
                                    </li>
                                    <li>
                                        <p className="BlogPage-Text">
                                            <span className="mr-4">Icon</span>
                                            One design for your desktop, tab and mobile.
                                        </p>
                                    </li>
                                    <li>
                                        <p className="BlogPage-Text">
                                            <span className="mr-4">Icon</span>
                                            One design for your desktop, tab and mobile.
                                        </p>
                                    </li>
                                </ul> */}
        {/* </div>
                        </div>
                        </div>
                        <div className="row pt-4 pt-sm-5">
                        <div className="col-12 col-lg-6 px-1 px-md-3 order-12 order-sm-12 order-md-12 order-lg-1 order-xl-1">
                            <div className="p-3">
                                <h2 className="BlogPage-Header mt-5">Solutions</h2>
                                <p className="BlogPage-Text pt-4 pb-2">
                                    Finally, it was decided that website would be built on Web Progressive Application (WPA) namely React JS. This latest technology allows you to create world class website with state-of-art UI/UX and that could also work without internet for ages. The website was created with 100% custom coding. Not a single framework was taken from third-party platforms. Our designers and developers had to undergo great challenges as completing this project on time was another milestone. 
                                </p> */}
        {/* <p className="BlogPage-Text pb-4">
                                Creating an elegant website with super UI/UX is something that can be done by a lot of companies, but making this website run without internet is something that only few companies could do. This is something that has never been done before in Pakistan by any builder and developer.
                                </p> */}
        {/* <ul className="text-center text-md-left">
                                    <li>
                                        <p className="BlogPage-Text">
                                            <span className="mr-4">Icon</span>
                                            One design for your desktop, tab and mobile.
                                        </p>
                                    </li>
                                    <li>
                                        <p className="BlogPage-Text">
                                            <span className="mr-4">Icon</span>
                                            One design for your desktop, tab and mobile.
                                        </p>
                                    </li>
                                    <li>
                                        <p className="BlogPage-Text">
                                            <span className="mr-4">Icon</span>
                                            One design for your desktop, tab and mobile.
                                        </p>
                                    </li>
                                </ul> */}
        {/* </div>
                        </div>
                        <div className="col-12 col-lg-6 px-1 px-md-5 mt-5 order-1 order-sm-1 order-md-1 order-lg-12 order-xl-12">
                            <div className="NextBlog-Img mb-3 mt-5">
                                <img src={NextBlogImg}/>
                            </div>
                        </div>
                        </div>
                        <div className="row pt-sm-5 pt-4">
                        <div className="col-12 my-5 text-center">
                            <h2 className="BlogPage-Header">
                                Scroreboard
                            </h2>
                        </div>
                        <div className="col-12 col-md-6 text-center">
                            <div className="Seperator">
                                <ul>
                                    <li>
                                        <p className="BlogPage-Text">
                                            <span className="mr-4">Icon</span>
                                            One design for your desktop, tab and mobile.
                                        </p>
                                    </li>
                                    <li>
                                        <p className="BlogPage-Text">
                                            <span className="mr-4">Icon</span>
                                            One design for your desktop, tab and mobile.
                                        </p>
                                    </li>
                                    <li>
                                        <p className="BlogPage-Text">
                                            <span className="mr-4">Icon</span>
                                            One design for your desktop, tab and mobile.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
        {/* <div className="col-12 col-md-6 text-center">
                            <div className="Seperator2">
                                <ul>
                                    <li>
                                        <p className="BlogPage-Text">
                                            <span className="mr-4">Icon</span>
                                            One design for your desktop, tab and mobile.
                                        </p>
                                    </li>
                                    <li>
                                        <p className="BlogPage-Text">
                                            <span className="mr-4">Icon</span>
                                            One design for your desktop, tab and mobile.
                                        </p>
                                    </li>
                                    <li>
                                        <p className="BlogPage-Text">
                                            <span className="mr-4">Icon</span>
                                            One design for your desktop, tab and mobile.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 my-5 text-center">
                            <h2 className="BlogPage-Header">Explore more Case Studies</h2>
                        </div>
                        <MoreCaseTemplate title1={"Oxford"} description1={"Desc"} image1={NextBlogImg} Link1={"/oxford-case-study"} title2={"Insignia"} description2={"Desc"} image2={NextBlogImg} Link2={"/Insignia"}/> */}
        {/* <div className="col-12 col-md-6 px-1 px-md-5 mb-5">
                            <div className=" BlogContent-bg">
                                <div className="NextBlog-img mb-3">
                                    <img src={NextBlogImg}/>
                                </div>
                                <div className="p-4">
                                    <h2 className="BlogPage-Title">Case Study Title</h2>
                                    <p className="BlogPage-Text">
                                    Aenean consectetur massa quis sem volutpat, a condimentum tortor pretium. Cras id ligula consequat, sagittis nulla at,
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 px-1 px-md-5 mb-5">
                            <div className=" BlogContent-bg">
                                <div className="NextBlog-img mb-3">
                                    <img src={NextBlogImg}/>
                                </div>
                                <div className="p-4">
                                    <h2 className="BlogPage-Title">Case Study Title</h2>
                                    <p className="BlogPage-Text">
                                    Aenean consectetur massa quis sem volutpat, a condimentum tortor pretium. Cras id ligula consequat, sagittis nulla at,
                                    </p>
                                </div>
                            </div>
                        </div> */}
        {/* </div>
                </div> */}
        {/* <div className="row contact_container">
                    <div className="col-md-2 mt-5"></div>
                    <div className="col-md-8 text-center my-5 py-5 px-5">
                        <img className="" src={diamondHomes} height="100"/>
                        <h1 className="text-capitalize contact-header2">SEO STRATEGY</h1>
                        <p className="header-description">Our SEO has increased the digital traffic for diamond homes. Greater visibility ranked higher, and competitors will have a material impact.
                        </p>

                        
                    </div>
                    <div className="col-md-2 mt-5"></div>
                </div>
                <div className="container case-study-description">
                        <div className="col-12">
                            <h2>
                            Web Progressive Application (WPA) Website
                            </h2>
                            <p>
                            How ExpoBird helped Diamond Homes to create a lethally fast website that could work even without internet where there is high ratio of internet signals interruption?
                            </p>
                            <h2 >
                            Summary
                            </h2>
                            <p>
                            Diamond Homes is a very renowned name in the field of Builders and Developers. With their long tale of successful projects, their next target was to capture the market of newly and state-of-art developed Bahria Town Karachi.<br/>
                            Since Bahria Town Karachi is located on main National Highway, the internet signals and mobile networks disruption is normal thing. Keeping this problem in mind, ExpoBird had to go out of the box and come up with the solution that not only satisfied Diamond Homes’ requirements of fast and responsive website, but also it should be able to work when there is no internet.
                            </p>
                            <h2 >
                            Insights
                            </h2>
                            <p>
                            ExpoBird with its team of full-stack developers called upon a meeting to drive the best solution for Diamond Homes.  Creating an elegant website with super UI/UX is something that can be done by a lot of companies, but making this website run without internet is something that only few companies could do. This is something that has never been done before in Pakistan by any builder and developer. 
                            </p>
                            <h2 >
                            Solution
                            </h2>
                            <p>
                            Finally, it was decided that website would be built on Web Progressive Application (WPA) namely React JS. This latest technology allows you to create world class website with state-of-art UI/UX and that could also work without internet for ages. The website was created with 100% custom coding. Not a single framework was taken from third-party platforms. Our designers and developers had to undergo great challenges as completing this project on time was another milestone. 
                            </p>

                        </div>
                </div>
                <div className="row marginTop3">
                    <div className="col-12 case-study-image ">
                        <img src={OxfordBanner} width="100%" height="500"/>
                    </div>                    
                </div>
                <div className="row text-center">
                <div className="container case-study-description">
                    <div className="row">
                        <div className="col-12">
                            <h2>
                                    Result
                            </h2>
                            <p>
                            By virtue of Allah, ExpoBird passed the exams with flying colours. A lethally fast website that could work without internet was created with luscious designs. As a result of this, now all stakeholders can surf the Diamond Homes’ website on mobile, tablets and other networking devices during their drive on highway and all the areas where there is high disruption of internet. 
                            <br/>
                            For the first time, this is done in the history of Pakistan that such an initiative is taken by any builder and developer and we are proud that we were leading this project.
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4 text-center">
                        <h2 className="case-study-result">
                                   39%
                            </h2>  
                            <p>
                            INCREASE IN<br/>ORGANIC TRAFFIC
                            </p>
                        </div>
                        <div className="col-4 text-center">
                        <h2 className="case-study-result">
                                  60%
                            </h2>  
                            <p>
                            TRAFFIC INCREASE IN KEY <br/>MARKET - PAKISTAN
                            </p>
                        </div>
                        <div className="col-4 text-center">
                        <h2 className="case-study-result">
                                   30%
                            </h2>  
                            <p>
                            TRAFFIC INCREASE IN KEY <br/>MARKET - DUBAI
                            </p>
                        </div>
                        </div>

                </div>
                </div>
                <Ctafifth/>
                <ContactForm/> */}
      </div>
    );
  }
}
