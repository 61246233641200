function scrollText(){
    const inViewport = (entries, observer) => {
        entries.forEach((entry) => {
          entry.target.classList.toggle("is-inviewport", entry.isIntersecting);
        });
      };
      const Obs = new IntersectionObserver(inViewport);
      const obsOptions = {};
      const ELs_inViewport = document.querySelectorAll("[data-inviewport=true]");
      const scrollText = document.querySelectorAll(".scroll-text");
    
      ELs_inViewport.forEach((EL, i) => {
        Obs.observe(EL, obsOptions);
    
        window.addEventListener("scroll", () => {
          if (ELs_inViewport[0].classList.contains("is-inviewport")) {
            scrollText[0].style.transform =
              "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, " +
              -0.5 * scrollText[0].getBoundingClientRect().top +
              ", 0, 0, 1)";
          }
        });
    
        window.addEventListener("scroll", () => {
          if (ELs_inViewport[1].classList.contains("is-inviewport")) {
            scrollText[1].style.transform =
              "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, " +
              1 * scrollText[1].getBoundingClientRect().top +
              ", 0, 0, 1)";
          }
        });
      });
}


export { scrollText };


