import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const ThankYou = () => {
  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href="https://www.expobird.com/thankyou"
          data-react-helmet="true"
        />
        <title>Thankyou | ExpoBird</title>
        <meta
          name="description"
          content="We have recieved your email. Our Customer service team will contact you shortly."
        />
      </Helmet>
      <div className="container mt-5">
        <div className="row pt-0 pt-md-5">
          <div className="col-12 col-lg-3"></div>
          <div className="col-12 col-lg-6 p-5 thankyouCard text-center">
            <h1 className="thankyouHead">Thank you!</h1>
            <p className="mt-4 p-style">
              We have recieved your email. <br />
              Our Customer service team will contact you shortly.
            </p>
            <div className="mt-4">
              <Link to="/">
                <button className="rf">Go Home</button>
              </Link>
            </div>
          </div>
          <div className="col-12 col-lg-3"></div>
        </div>
      </div>
    </>
  );
};

export default ThankYou;
