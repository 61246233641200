import React, { Component } from "react";
import Goo from "../ui/SpringAnimation";
import { Link } from "react-router-dom";
import LightSpeed from "react-reveal/LightSpeed";

class AnimationBanner extends Component {
  render() {
    return (
      <div>
        <div className="row bnr-video align-items-center ">
          <Goo />
          <div className="container">
            <LightSpeed left>
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-5 mt-lg-0">
                <div className="heading-text text-center text-sm-center text-md-center text-lg-left">
                  <h1 id="heading-main">Animation with ExpoBird</h1>
                  <h2 className="py-3" id="sub-text">
                    Pakistan’s #1 Animation Company based in Karachi
                  </h2>
                  <Link to="/contact-us">
                    <button className="rf">Get Started</button>
                  </Link>
                </div>
              </div>
            </LightSpeed>
          </div>
        </div>
      </div>
    );
  }
}

export default AnimationBanner;
