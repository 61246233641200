import "./seo.css";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import $ from "jquery";
import Banner from "../pages/banner";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Recaptcha from "react-recaptcha";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

class Careers extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      email: "",
      contact: "",
    //   message: "",
      marketing: "",
      fileInput: "",
      isVerified : false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.recaptchaLoaded = this.recaptchaLoaded.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
  }

  recaptchaLoaded (){
    console.log("loaded")
  }

  verifyCallback (res){
    if(res){
      this.setState({
        isVerified:true
      })
    }
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  handleSubmit(event) {
    event.preventDefault();
    if(this.state.isVerified){

    $(".thankyou-message").css({
      display: "block",
    });

    axios({
      method: "post",
      url: "https://www.expobird.com/emailHiring.php",
      // headers: { "content-type": "application/json" },
      headers: { "content-type": "multipart/form-data" },
      data: this.state,
    })
      .then((result) => {
          console.log("here", result);
          setTimeout(() => {
            $(".thankyou-message").css({
              display: "none",
            });
          }, 2000);
          setTimeout(() => {
            this.props.history.push("/thankyou");
          }, 2500);
      })
      .catch((error) => this.setState({ error: error.message }));
    $(".services-cta").css("opacity", "0.5");
    $(".services-cta").attr("disabled", "true");
    $(".services-cta").attr("disabled", "true");
  }else{
      alert("Please verify that you are a human!")
    }
  }

  hack() {
    alert("asda");
  }

  render() {
    return (
      <div>
        <Helmet>
          <link
            rel="canonical"
            href="https://www.expobird.com/careers"
            data-react-helmet="true"
          />
          <title>Careers | ExpoBird</title>
          <meta
            name="description"
            content="For any queries, feel free to contact us. ExpoBird is 24/7 live to entertain you."
          />
        </Helmet>
        <div className="row background-2">
          <div className="container text-center">
            <h1 id="heading-main">Careers</h1>
          </div>
        </div>
        <Link to="/" className="linkText">
          Home
        </Link>{" "}
        &#xbb;{" "}
        <Link to="/careers" className="linkText">
          Careers
        </Link>
        <div className="container">
          <div className="row ">
            <div className="thankyou-message p-3">
              Thank you! We've received your message.
            </div>

            {/* <div className=" container"> */}

            {/* <div className="col-12 col-sm-12 col-md-12 col-lg-2 mt-5"></div> */}
            <div className="col-12 col-sm-12 col-md-8 col-lg-8 text-left my-lg-5 py-5 order-lg-1 order-12">
              {/* <h4 className="text-uppercase ">Contact us</h4> */}
              <h1 className="text-capitalize contactSize ">
              Discover Careers with Expobird
              </h1>
              <p className="header-description">
              Discover exciting career opportunities at Expobird. Join us, where innovation meets growth, and make your mark today!
              </p>
              <h3>
                <a className="contactInfo" href="tel:+92-330-2033334">
                  +92-330-2033334
                </a>
              </h3>
              <h3>
                <a className="contactInfo" href="tel:+92-311-5994021">
                  +92-311-5994021
                </a>
              </h3>
              <h3>
                <a className="contactInfo" href="mailto:info@expobird.com">
                  info@expobird.com
                </a>
              </h3>

              <div className="row justify-content-between mt-5">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 px-3 contactInfoMargin py-4 Cards contactAdressCard">
                  <h5 className="contactAdress ">Headquarter</h5>
                  <h5 className="contactAdressInfo">Karachi Office</h5>
                  <p>
                    Plot No.129-O, Ground Floor, P.E.C.H.S, Block 2,
                    Karachi,Sindh 75100, Pakistan
                  </p>
                  <div>
                    <iframe
                      className="mobile-map"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d760.9428869688304!2d67.05085514018442!3d24.874954782344904!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33f171277b2e7%3A0xd279dda1f08cd42!2sExpoBird%20%7C%20Social%20Media%20%26%20Top%20Digital%20Marketing%20Agency%20%7C%20SEO%20services!5e0!3m2!1sen!2s!4v1621870023166!5m2!1sen!2s"
                      allowfullscreen=""
                      loading="lazy"
                    ></iframe>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 px-3 py-4  Cards contactAdressCard mt-3 mt-sm-0">
                  <h5 className="contactAdressInfo">US Office</h5>
                  <p className="">
                    9585 Jones Rd, Houston, TX 77065, United States
                  </p>
                  <div>
                    <iframe
                      className="mobile-map"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3458.339385193615!2d-95.587519184888!3d29.912130181930586!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640d1b7a0a250e1%3A0x61788b2dc23644d8!2s9585%20Jones%20Rd%2C%20Houston%2C%20TX%2077065%2C%20USA!5e0!3m2!1sen!2s!4v1615297184915!5m2!1sen!2s"
                      allowfullscreen=""
                      loading="lazy"
                    ></iframe>
                  </div>
                </div>
              </div>
              <div className="row  mt-3">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 px-3 contactInfoMargin py-4 Cards contactAdressCard">
                  <h5 className="contactAdressInfo">UAE Office</h5>
                  <p className="">
                    Suite # 405, Al - Zarouni Building, Opp Central Bank Of
                    Sharjah U.A.E.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 text-left my-lg-5 py-5 order-lg-12 order-1">
              {/* <p id="thank-you-message">
                        Thank you for contacting us. We will be in touch with you very soon.
                        </p> */}
              <h4 className="mb-3" id="ah2">
                <b>Apply Now: Your Future Starts Here!</b>
              </h4>
              <form
                action="emailHiring.php"
                method="post"
                onSubmit={this.handleSubmit}
                encType="multipart/form-data"
              >
                <div className="mb-3">
                  <input
                    type="text"
                    className="cf-fields"
                    placeholder="Your Name"
                    name="username"
                    value={this.state.value}
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    id=""
                    className="cf-fields"
                    placeholder="Email"
                    name="email"
                    value={this.state.value}
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <select
                    onChange={this.handleChange}
                    name="marketing"
                    id=""
                    className="cf-fields"
                    required
                  >
                    <option value="">I'm interested in...</option>
                    <option value="Digital Marketing">
                    Digital Marketing
                    </option>
                    <option value="Social Media Marketing">
                    Social Media Marketing
                    </option>
                    <option value="Website Development">
                      Website Development
                    </option>
                    <option value="Human Resource">
                    Human Resource
                    </option>
                    <option value="Search Engine Optimization">
                    Search Engine Optimization
                    </option>
                    <option value="Graphic Designing">
                    Graphic Designing
                    </option>
                    <option value="Video Animation">
                      Video Animation
                    </option>
                    <option value="Business Development">
                    Business Development
                    </option>
                    <option value="Content Writing">
                      Content Writing
                    </option>
                  </select>
                </div>
                {/* <div className="mb-3">
                  <textarea
                    id=""
                    onChange={this.handleChange}
                    cols="30"
                    rows="3"
                    className="cf-fields"
                    name="message"
                    required
                  ></textarea>
                </div> */}
                <div className="mb-3">
                  {/* <input
                    type="text"
                    onChange={this.handleChange}
                    id=""
                    name="contact"
                    placeholder="Your Contact#"
                    className="cf-fields"
                    required
                  /> */}
                  <PhoneInput
                  country={"pk"}
                  name="contact"
                  placeholder="Contact#"
                  value={this.state.contact}
                  onChange={contact => this.setState({ contact })}
                />
                </div>
                <div className="mb-3 cf-fields">
                <label for="fileInput">Drop your Resume :</label>
        
                <input 
                type="file" 
                name="fileInput" 
                accept=".pdf, .doc, .docx" 
                onChange={this.handleChange}
                required/>

                  {/* <input
                    type="text"
                    onChange={this.handleChange}
                    id=""
                    name="contact"
                    placeholder="Your Contact#"
                    className="cf-fields"
                    required
                  /> */}
                </div>
                <Recaptcha
              sitekey="6Lfq9c4oAAAAAPQzYqLXUO4nmYM3WXHJOdpkXJHZ"
              render="explicit"
              onloadCallback={this.recaptchaLoaded}
              verifyCallback={this.verifyCallback}
              />
                <input
                  type="submit"
                  name="submit"
                  className="services-cta cf-submit mt-3"
                />
              </form>
            </div>
            {/* <div className="col-12 col-sm-12 col-md-12 col-lg-2 "></div> */}
          </div>
        </div>
        {/* <div className="container">
                    <div className="row ">
                        <div className="col-12 col-sm-12 col-md-4 col-lg-4 text-center px-3 py-4">
                            <div className="px-3 py-4 Cards">
                                <img width="100" className="Contact-icon" src={location} alt=""/>
                                <h5 className="py-4">Office Address</h5>
                                <p className="pb-lg-3 pb-md-0 pb-sm-0">Plot 3H, Flat G-2</p>
                                <p className="pb-lg-3 pb-md-0 pb-sm-0">block 6 PECHS</p>
                                <p className="pb-lg-3 pb-md-0 pb-sm-0">Sharah e Faisal</p>
                                <p className="pb-lg-3 pb-md-0 pb-sm-0">Karachi, Pakistan</p>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-4 col-lg-4 text-center px-3 py-4">
                            <div className="py-4 px-3 Cards">
                                <img width="100" className="Contact-icon" src={Mail} alt=""/>
                                <h5 className="py-4">Contact Us</h5>
                                <p className="pb-lg-3 pb-md-0 pb-sm-0">Email:</p>
                                <p className="pb-lg-3 pb-md-0 pb-sm-0">info@expobird.com</p>
                                <p className="pb-lg-3 pb-md-0 pb-sm-0">Contact:</p>
                                <p className="pb-lg-3 pb-md-0 pb-sm-0">+92 333 3375061</p>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-4 col-lg-4 text-center px-3 py-4">
                            <div className="py-4 px-3 Cards">    
                                <img width="100" className="Contact-icon" src={HomeLogo} alt=""/>
                                <h5 className="py-4">Availability</h5>
                                <p className="pb-lg-3 pb-md-0 pb-sm-0">Monday to Saturday</p>
                                <p className="pb-lg-3 pb-md-0 pb-sm-0">9am to 6pm</p>
                                <p className="pb-lg-3 pb-md-0 pb-sm-0">Sunday</p>
                                <p className="pb-lg-3 pb-md-0 pb-sm-0">Closed</p>
                            </div>    
                        </div>
                        <div className="col-2 mt-5"></div>
                        <div className="col-8 mt-5 contactCard">
                            <h2 className="text-center">Send a Message</h2>
                            <p className="text-center pt-4">Select a topic for inquiry and fill the form to contact us.</p>
                        </div>
                        <div className="col-2 mt-5"></div>
                        <ContactForm/>
                    </div>
                </div>  */}
      </div>
      // </div>
    );
  }
}
export default withRouter(Careers);