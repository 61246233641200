import React, { Component, createRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import {
  Navbar,
  Nav,
  UncontrolledCollapse,
  NavItem,
} from 'reactstrap';
import Amazon1 from "../assets/Amazon1.gif";
import expoBirdLogo from '../assets/Expobird-whitelogo.png'
import expoBirdSticky from '../assets/sticky menu navigation.png'
import whatsApp from '../assets/whatsapp icon.jpg'
import $ from "jquery"
import OnlineMarketing from '../assets/Online Marketing.svg'
import graphicsDesign from '../assets/services 1.mp4'
import videoProduction from '../assets/video production expobird.mp4'
import animation from '../assets/video animation expobird.mp4'
import digimarket from '../assets/digital marketing expobird.mp4'
import services4 from '../assets/digital-marketing-expobird.gif'
import services5 from '../assets/web-development-expobird.gif'
import services6 from '../assets/social-media-expobird.gif'
import services2 from '../assets/video-production-expobird.gif'
import services3 from '../assets/video-animation-expobird.gif'
import services1 from '../assets/services-1.gif'
import sm from '../assets/social media expobird.mp4'
import whatsapp from '../assets/whatsapp.svg'
import {Link} from "react-router-dom";
class Navbars extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.shoot = this.shoot.bind(this);
    this.mouseOut = this.mouseOut.bind(this);
    this.menuCollapse = this.menuCollapse.bind(this);
    // this.myFunction = this.myFunction.bind(this);
    // this.openNav = this.openNav.bind(this);
    this.MobCloseNav = this.MobCloseNav.bind(this);
    this.makeMuted = this.makeMuted.bind(this);
  }

  shoot() {
    /* $('.service-menu').toggleClass('dropdown-show') */
    $(".dropdown-content").css({
      display: "none",
    });
    /* $('.service-menu').css({
      'visibility' : 'hidden',
      'opacity' : '0',
      'zIndex': '-1'

    }); */
  }
  mouseOut() {
    $(".service-menu").css({
      display: "block",
      visibility: "visible",
      opacity: "1",
      zIndex: "1",
    });
  }
  mouseIsOut() {
    $(".dropdown-content").css({
      display: "none",
    });
  }
  menuCollapse() {
    $(".service-menu").css({
      display: "block",
      visibility: "visible",
      opacity: "1",
      zIndex: "1",
    });
  }

  makeMuted  (elt) {
    if (elt) {
      elt.muted = true;
    }
  };
  componentDidMount() {
    if (window.matchMedia("(min-width: 768px)").matches) {
      // console.log("yeah");
      $(window).scroll(() => {
        if (
          document.body.scrollTop > 80 ||
          document.documentElement.scrollTop > 80
        ) {
          $(".navbar").css({
            position: "fixed",
            zIndex: 2,
            backgroundColor: "white",
            padding: "0",
          });
          $("#logo").attr("src", expoBirdSticky);
          $("#logo").css("width", "62px");
          $(".menu a:link").css({
            color: "#2E3192",
            fontSize: "15px",
          });
          $(".nostyling").css("color", "#2E3192");
          $(".menu").css({
            boxShadow: "rgba(210, 210, 210, 0.5) 0px 3px 16px 0px",
          });
        } else {
          $(".navbar").css({
            position: "relative",
            backgroundColor: "#2E3192",
          });
          $("#logo").attr("src", expoBirdLogo);
          $("#logo").css("width", "76px");
          $(".menu a:link").css({
            color: "white",
            fontSize: "15px",
          });
          $(".nostyling").css("color", "white");
          $(".menu").css({
            boxShadow: "none",
          });
          $(".dropdown-menu-headings").css("color", "black");
        }
      });
    }
  }
  // $(".dropdown-content").mouseover(function(){
  //   $(".dropdown-content").css("background-color", "yellow");
  // });

  openNav() {
    document.getElementById("mySidebar").style.width = "50%";
    document.getElementById("main").style.marginRight = "40px";
    document.getElementById("menu-icon").style.display = "none";
    // var x = window.matchMedia("(max-width: 700px)")
    // this.myFunction(x); // Call listener function at run time
    // x.addListener(this.myFunction);
  }

  // myFunction(x){
  //   if (x.matches) { // If media query matches
  //   document.getElementById("mySidebar").style.width = "100%";
  //   document.getElementById("mySidebar").style.textAlign = "center";
  //   document.getElementById("main").style.marginRight = "0px";

  //   }
  // }

  closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("main").style.marginRight = "0";
    document.getElementById("menu-icon").style.display = "flex";
  }

  MobCloseNav() {
    if (window.matchMedia("(max-width: 767px)").matches) {
      document.getElementById("mySidebar").style.width = "0";
      document.getElementById("main").style.marginRight = "0";
      document.getElementById("menu-icon").style.display = "flex";
    }
  }

  render() {
    return (
      <div>
        <Navbar className="menu " fixed="top">
          <div id="mySidebar" className="sidebar">
            <Link className="nav-link closebtn" to="#" onClick={this.closeNav}>
              ×
            </Link>
            <Link
              onClick={this.MobCloseNav}
              className="active nav-link"
              to="/our-clients"
            >
              Clients
            </Link>
            <Link
              className="active nav-link dropdown-toggle dropdownLink"
              id="toggler"
              to="/"
            >
              Services
            </Link>
            <UncontrolledCollapse toggler="#toggler">
              <Link
                onClick={this.MobCloseNav}
                className="active nav-link"
                to="/graphic-design"
              >
                <small>Graphics & Design</small>
              </Link>
              <Link
                onClick={this.MobCloseNav}
                className="active nav-link"
                to="/web-development"
              >
                <small>Web & App Development</small>
              </Link>
              <Link
                onClick={this.MobCloseNav}
                className="active nav-link"
                to="/animation"
              >
                <small>Animation</small>
              </Link>
              <Link
                onClick={this.MobCloseNav}
                className="active nav-link"
                to="/digital-marketing"
              >
                <small>Digital Marketing</small>
              </Link>
              <Link
                onClick={this.MobCloseNav}
                className="active nav-link"
                to="/video-production-service"
              >
                <small>Video Production</small>
              </Link>
              <Link
                onClick={this.MobCloseNav}
                className="active nav-link"
                to="/search-engine-optimization"
              >
                <small>Search Engine Optimisation</small>
              </Link>
            </UncontrolledCollapse>

            {/* <a  onClick={this.MobCloseNav} className="active nav-link" href="https://expobird.com/portfolio">Portfolio</a> */}
            {/* <Link onClick={this.MobCloseNav} className="active nav-link" to="/case-study">Case Study</Link> */}
            {/* <a onClick={this.MobCloseNav} className="active nav-link" href="https://expobird.com/blogs">Blogs</a>  */}
            <Link
              onClick={this.MobCloseNav}
              className="active nav-link"
              to="/about-us"
            >
              About Us
            </Link>
            <Link
              onClick={this.MobCloseNav}
              className="active nav-link"
              to="/case-study"
            >
              Case Study
            </Link>
            <a
              onClick={this.MobCloseNav}
              className="active nav-link"
              href="https://www.expobird.com/blog"
            >
              Blogs
            </a>
            <Link
              onClick={this.MobCloseNav}
              className="active nav-link"
              to="/contact-us"
            >
              Contact Us
            </Link>
            <Link
              onClick={this.MobCloseNav}
              className="active nav-link"
              to="/careers"
            >
              Careers
            </Link>
          </div>
          <Link to="/">
            <img
              className=""
              src={expoBirdLogo}
              id="logo"
              alt="ExpoBird Logo"
            />
          </Link>
          <Nav>
            <NavItem onClick={this.shoot}>
              <Link className="active nav-link" to="/our-clients">
                Clients
              </Link>
            </NavItem>
            <NavItem
              onMouseOut={this.mouseIsOut}
              onMouseOver={this.mouseOut}
              className="dropdown"
              tab-index="0"
            >
              <Link to="#" className="active nav-link">
                Services <FontAwesomeIcon icon={faCaretDown} />
              </Link>
              <div
                onMouseOut={this.mouseIsOut}
                className="dropdown-content service-menu"
                id="dropdown-contents"
              >
                <div className="row">
                  <div className="col-6">
                    <div id="MainNav" onClick={this.shoot}>
                      <Link className="link-d" to="/digital-marketing">
                        <div className="if">
                          {/* <video
                            className="sub-menu-video"
                            ref={this.makeMuted}
                            loop
                            autoPlay
                            muted
                          >
                            <source src={sm} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video> */}

                          <img className="sub-menu-video" src={services6} alt=""/>
                          <div className="ml-3">
                            <p className="dropdown-menu-headings">
                              Digital Marketing
                            </p>
                            <p className="dropdown-menu-text">
                              Get your company name out to the public at large
                              using Social Media.
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div id="MainNav" className="mt-3" onClick={this.shoot}>
                      <Link className="link-d" to="/search-engine-optimization">
                        <div className="if">
                          {/* <video
                            className="sub-menu-video"
                            ref={this.makeMuted}
                            loop
                            autoPlay
                            muted
                          >
                            <source src={digimarket} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video> */}

                          <img className="sub-menu-video" src={services4} alt=""/>
                          <div className="ml-3">
                            <p className="dropdown-menu-headings">
                              Search Engine Optimisation
                            </p>
                            <p className="dropdown-menu-text">
                              Use your audience habit and get more traffic now.
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div id="MainNav" className="mb-3" onClick={this.shoot}>
                      <Link className="link-d" to="/video-production-service">
                        <div className="if">
                          {/* <video
                            className="sub-menu-video"
                            ref={this.makeMuted}
                            loop
                            autoPlay
                            muted
                          >
                            <source src={videoProduction} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video> */}
                          <img className="sub-menu-video" src={services2} alt=""/>
                          <div className="ml-3">
                            <p className="dropdown-menu-headings">
                              Video Production
                            </p>
                            <p className="dropdown-menu-text">
                              Our in-house fully-equipped video production team.{" "}
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div id="MainNav" className="mt-3" onClick={this.shoot}>
                      <Link className="link-d" to="/amazon-services">
                        <div className="if">
                        <img
                            className="sub-menu-video"
                            src={Amazon1}
                            width={64}
                            height={64}
                          />
                          {/* <img width="32px" className="mb-3" src={Seo} alt=""/> */}
                          <div className="ml-3">
                            <p className="dropdown-menu-headings">
                              Amazon Services
                            </p>
                            <p className="dropdown-menu-text">
                              Build Your Business Today.
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-6">
                    <div id="MainNav" onClick={this.shoot}>
                      <Link className="link-d" to="/social-media-marketing">
                        <div className="if">
                          {/* <img width="32px" className="mb-3" src={OnlineMarketing} alt=""/> */}
                          <img
                            className="sub-menu-video"
                            src={OnlineMarketing}
                          />

                          <div className="ml-3">
                            <p className="dropdown-menu-headings">
                              Social Media Marketing
                            </p>
                            <p className="dropdown-menu-text">
                              Win the hearts of your existing and potential
                              customers by having a presence on Facebook and
                              other forums.
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div id="MainNav" onClick={this.shoot}>
                      <Link className="link-d" to="/web-development">
                        <div className="if">
                          {/* <video
                            className="sub-menu-video"
                            ref={this.makeMuted}
                            loop
                            autoPlay
                            muted
                          >
                            <source src={services5} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video> */}
                          <img className="sub-menu-video" src={services5} alt=""/>
                          <div className="ml-3">
                            <p className="dropdown-menu-headings">
                              Web & App Development
                            </p>
                            <p className="dropdown-menu-text">
                              A website is the digital face of your business.{" "}
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div id="MainNav" onClick={this.shoot}>
                      <Link className="link-d" to="/animation">
                        <div className="if">
                          {/* <video
                            className="sub-menu-video"
                            ref={this.makeMuted}
                            loop
                            autoPlay
                            muted
                          >
                            <source src={animation} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video> */}
                          <img className="sub-menu-video" src={services3} alt=""/>
                          <div className="ml-3">
                            <p className="dropdown-menu-headings">Animation</p>
                            <p className="dropdown-menu-text">
                              Convey your messages and explain your business
                              with eye-catchy animated videos
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div id="MainNav" onClick={this.shoot}>
                      <Link className="link-d" to="/graphic-design">
                        <div className="if">
                          {/* <video
                            className="sub-menu-video"
                            ref={this.makeMuted}
                            loop
                            autoPlay
                            muted
                          >
                            <source src={graphicsDesign} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video> */}
                          <img className="sub-menu-video" src={services1} alt=""/>
                          <div className="ml-3">
                            <p className="dropdown-menu-headings">
                              {" "}
                              Graphics & Design
                            </p>
                            <p className="dropdown-menu-text">
                              Nothing lasts forever. And so does your graphics
                              and design.{" "}
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </NavItem>
            {/* <NavItem onClick={this.shoot} >
              <a  className="nav-link" href="https://expobird.com/portfolio">Portfolio</a>
            </NavItem> */}
            {/* <NavItem onClick={this.shoot} >
              <Link className="nav-link" to="/case-study">Case Study</Link>
            </NavItem> */}
            {/* <NavItem>
              <Link className="nav-link" to="/about-us">About Us</Link>
            </NavItem> */}
            {/* <NavItem  className="dropdown" tab-index="0">
              
                <Link className="nav-link" to="/portfolio-section">
                  Portfolio <FontAwesomeIcon icon={faCaretDown} />
                </Link> */}
            {/* Below is the Portfolio mega menu section */}
            {/* <div className="dropdown-content" id="dropdown-contents">
                <div className="row">
                  <div className="col-6">
                  <div id="MainNav" onClick={this.shoot}>
                      <a href="https://expobird.com/Portfolio/social-media-marketing-2/">
                        <div className="if">
                          
                          <img className="sub-menu-video" src={OnlineMarketing} />

                          <div className="ml-3">
                            <p className="dropdown-menu-headings">Social Media Marketing</p>
                            <p className="dropdown-menu-text">Win the hearts of your existing and potential customers by having a presence on Facebook and other forums.</p>
                          </div>
                        </div>  
                      </a>
                    </div>
                    <div id="MainNav" onClick={this.shoot}>
                      <a href="https://expobird.com/Portfolio/web-and-app-development/">
                        <div className="if">
                          <video className="sub-menu-video"  ref={this.makeMuted} loop autoPlay>
                            <source src={services5} type="video/mp4"/>
                            Your browser does not support the video tag.
                          </video>
                          
                          <div className="ml-3">
                            <p className="dropdown-menu-headings">Web & App Development</p>
                            <p className="dropdown-menu-text">A website is the digital face of your business. </p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div id="MainNav" onClick={this.shoot}>
                      <a href="https://expobird.com/Portfolio/animation/">
                        <div className="if">
                        <video className="sub-menu-video"  ref={this.makeMuted} loop autoPlay>
                            <source src={animation} type="video/mp4"/>
                            Your browser does not support the video tag.
                          </video>
                          
                          <div className="ml-3">
                            <p className="dropdown-menu-headings">Animation</p>
                            <p className="dropdown-menu-text">Convey your messages and explain your business with eye-catchy animated videos</p>
                          </div>
                        </div>
                        </a>
                    </div>
                  </div>
                  <div className="col-6">
                    <div id="MainNav" onClick={this.shoot}>
                      <a href="https://expobird.com/Portfolio/video-production/">
                        <div className="if">
                        <video className="sub-menu-video"  ref={this.makeMuted} loop autoPlay>
                            <source src={videoProduction} type="video/mp4"/>
                            Your browser does not support the video tag.
                          </video>
                          <div className="ml-3">
                            <p className="dropdown-menu-headings">Video Production</p>
                            <p className="dropdown-menu-text">Our in-house fully-equipped video production team. </p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div id="MainNav" onClick={this.shoot}>
                      <a href="https://expobird.com/Portfolio/search-engine-optimization-2/">
                        <div className="if">
                        <video className="sub-menu-video"  ref={this.makeMuted} loop autoPlay>
                            <source src={digimarket} type="video/mp4"/>
                            Your browser does not support the video tag.
                          </video>
                          <div className="ml-3">
                            <p className="dropdown-menu-headings">Search Engine Optimisation</p>
                            <p className="dropdown-menu-text">Use your audience habit and get more traffic now.</p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div id="MainNav" onClick={this.ToggleFunction}>
                      <a href="https://expobird.com/Portfolio/digital-marketing-2/">
                        <div className="if">
                        <video className="sub-menu-video"  ref={this.makeMuted} loop autoPlay>
                            <source src={sm} type="video/mp4"/>
                            Your browser does not support the video tag.
                          </video>
                          <div className="ml-3">
                            <p className="dropdown-menu-headings">Digital Marketing</p>
                            <p className="dropdown-menu-text">Get your company name out to the public at large using Social Media.</p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </NavItem>*/}

            {/* <NavItem onClick={this.shoot}>
              <a  className="nav-link" href="https://expobird.com/blogs">Blogs</a>
            </NavItem> */}

            <NavItem onClick={this.shoot}>
              <a className="nav-link" href="https://www.expobird.com/blog">
                Blogs
              </a>
            </NavItem>

            <NavItem onClick={this.shoot}>
              <Link className="nav-link" to="/case-study">
                Case Study
              </Link>
            </NavItem>

            <NavItem onClick={this.shoot}>
              <Link className="nav-link" to="/about-us">
                About Us
              </Link>
            </NavItem>

            <NavItem onClick={this.shoot}>
              <Link className="nav-link" to="/contact-us">
                Contact Us
              </Link>
            </NavItem>

            <NavItem onClick={this.shoot}>
              <Link className="nav-link" to="/careers">
                Careers
              </Link>
            </NavItem>

            {/* <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Options 
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem>
                  Option 1
                </DropdownItem>
                <DropdownItem>
                  Option 2
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem>
                  Reset
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown> */}
          </Nav>
          {/* </Collapse> */}
          <div id="call-icon-container">
            <span>
              {" "}
              <a href="https://wa.me/923302033334" target="_blank">
                <img id="whatsapp-icon" src={whatsApp} alt="" />
              </a>{" "}
            </span>
            {/* <span><Link to="tel:+923333375061"><img  className="call-icon" src={call} alt="call expobird"/></Link></span> */}
          </div>
          <div id="menu-icon" onClick={this.openNav} className="openbtn">
            <button className="nostyling"> ☰ </button>
          </div>
        </Navbar>
        <div className="whatsapp-icon">
          <a href="https://wa.me/923302033334" target="_blank">
            <img
              width="16"
              height="16"
              src={whatsapp}
              alt="whatsapp expobird"
            />
          </a>
        </div>
      </div>
    );
  }
}

export default Navbars;